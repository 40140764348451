
import { useEffect, useState } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../contexts/ProtectedContext'

import { useLocation, Link } from "react-router-dom"


import { auth_axios } from '../../../libs/authWeb';
import { mixpanel_client_track } from '../../../libs/mixpanelClient';

import GoogleMapsModal from './GoogleMapsModal'
import ReactMarkdown from 'react-markdown'  // Import ReactMarkdown
import { day_map, format_phone_number, format_time, receptionist_action_map, receptionist_mode_map } from '../../../libs/formats'
import { ChevronDownIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline'
import { hours_are_identical } from '../../../libs/helpers'
import axios from 'axios'






const OrganizationPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // User data
  const [organization_data, set_organization_data] = useState({})
  const [organization_data_is_fetched, set_organization_data_is_fetched] = useState(false)

  // Refresh variable
  const [refresh_variable, set_refresh_variable] = useState(Date.now())

  // Modal
  const [google_maps_modal_is_open, set_google_maps_modal_is_open] = useState(false)





  const get_organization_data = async () => {

    // await axios.put('/api/test/four')


    // Set is_fetched to false
    set_organization_data_is_fetched(false)

    // Execute get user data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_organization_data(get_org_res.data.organization_data)
    set_organization_data_is_fetched(true)
  }


  

  // Renders 
  useEffect(() => {

    get_organization_data()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_organization_visited", global_context.user_id)

  }, [refresh_variable])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Business</h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Business settings
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">

            {/* Finish onboarding name */}
            {organization_data_is_fetched && !organization_data["onboarding_is_complete"] ? (
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Onboarding status</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">
                    <span>Incomplete</span>
                  </div>
                  <Link 
                    to="#"
                    // to="/onboarding" 
                    className="font-semibold text-blue-600 hover:text-blue-500"
                  >
                    Finish onboarding
                  </Link>
                </dd>
              </div>
            ) : (
              <></>
            )}

            {/* Organization name */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Business name</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    <span>{organization_data["organization_metadata"]["name"]}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                {/* Only available to owner */}
                {["owner"].includes(global_context.type)
                ? <Link to="update/name" className="font-semibold text-blue-600 hover:text-blue-500">
                    Update
                  </Link>
                : <></>}
              </dd>
            </div>

            {/* Receptionist */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">AI receptionist mode</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    <span>{receptionist_mode_map[organization_data["organization_metadata"]["receptionist_mode"]]}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                <Link to="update/receptionist/configuration" className="font-semibold text-blue-600 hover:text-blue-500">
                  Update
                </Link>
              </dd>
            </div>

            {/* Receptionist actions */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">AI receptionist actions</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    <div>{organization_data["organization_metadata"]["receptionist_actions"] && organization_data["organization_metadata"]["receptionist_actions"].map(action => receptionist_action_map[action]).join(",")}</div>
                    // <ReactMarkdown>{organization_data["organization_metadata"]["receptionist_instructions"]}</ReactMarkdown>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                <Link to="update/receptionist/actions" className="font-semibold text-blue-600 hover:text-blue-500">
                  Update
                </Link>
              </dd>
            </div>

            {/* Show business phone number alongside agent phone number, based on receptionist mode */}
            {!organization_data_is_fetched ? (
              <ReactLoading
                type='spokes'
                color='#343D46'
                height={20}
                width={20}
              />

            // Front desk agent mode
            ) : organization_data["organization_metadata"]["receptionist_mode"] === "front_desk" ? (
              <>
                {/* Front desk agent phone number */}
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Front desk AI receptionist phone number</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">
                      <span>{`${format_phone_number(organization_data["receptionist_agent"]["phone_number"])} (${organization_data["receptionist_agent"]["agent_metadata"]["name"]})`}</span>
                    </div>
                    {/* <Link to="update/_____" className="font-semibold text-blue-600 hover:text-blue-500">
                      Update
                    </Link> */}
                  </dd>
                </div>
                {/* Phone number */}
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Business phone number</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">
                      <span>{format_phone_number(organization_data["organization_metadata"]["phone_number"])}</span>
                    </div>
                    <Link to="update/phone-number" className="font-semibold text-blue-600 hover:text-blue-500">
                      Update
                    </Link>
                  </dd>
                </div>
              </>
            
            // After hours agent mode
            ) : organization_data["organization_metadata"]["receptionist_mode"] === "after_hours" ? (
              <>
                {/* Phone number */}
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Business phone number</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">
                      <span>{format_phone_number(organization_data["organization_metadata"]["phone_number"])}</span>
                    </div>
                    <Link to="update/phone-number" className="font-semibold text-blue-600 hover:text-blue-500">
                      Update
                    </Link>
                  </dd>
                </div>

                {/* After hours agent phone number */}
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">After hours AI receptionist phone number</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">
                      <span>{format_phone_number(organization_data["receptionist_agent"]["phone_number"])}</span>
                    </div>
                    {/* <Link to="update/_____" className="font-semibold text-blue-600 hover:text-blue-500">
                      Update
                    </Link> */}
                  </dd>
                </div>
              </>

            // Receptionist mode is not set yet
            ) : (
              <>
                {/* Phone number */}
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Business phone number</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">
                      <span>{format_phone_number(organization_data["organization_metadata"]["phone_number"])}</span>
                    </div>
                    <Link to="update/phone-number" className="font-semibold text-blue-600 hover:text-blue-500">
                      Update
                    </Link>
                  </dd>
                </div>
              </>
            )}

            {/* Business hours */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Business hours</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    <div className="space-y-4">

                      {/* Each day */}
                      {organization_data["organization_metadata"]["business_hours"].map((day, day_index) => (
                        <div key={day_index} className="flex flex-col space-y-2">
                          {/* Display fields */}
                          <div className="flex items-center space-x-4">
                            
                            {/* Day */}
                            <div className="">
                              {day_map[day.day]}
                            </div>

                            {/* Formatted windows */}
                            {day.windows.length === 0 ? (
                              // The business is closed
                              <div>
                                Closed
                              </div>
                            ) : (day.windows.length === 1) && (day.windows[0][0] === "0000") && (day.windows[0][1] === "2400") ? (
                              // The business is closed
                              <div>
                                Open 24 hours
                              </div>
                            ) : (
                              // Business is open
                              day.windows.map((window, window_index) => (
                                <div key={window_index} className="flex items-center space-x-1">

                                  {/* Start time */}
                                  <div className="">
                                    {format_time(window[0])}
                                  </div>
                                  
                                  <div className="">-</div>

                                  {/* End time */}
                                  <div className="">
                                    {format_time(window[1])}
                                  </div>

                                  {/* Comma */}
                                  {window_index < (day.windows.length - 1) ? (
                                    <div>,</div>
                                  ) : (
                                    <></>
                                  )}

                                </div>
                              ))
                            )}

                          </div>
                        </div> 
                      ))}

                    </div>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                <Link to="update/business-hours" className="font-semibold text-blue-600 hover:text-blue-500">
                  Update
                </Link>
              </dd>
            </div>

            {/* Human reachable hours */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Human reachable hours</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    <div className="space-y-4">

                      {/* Human reachable hours */}
                      {!hours_are_identical(organization_data["organization_metadata"]["business_hours"], organization_data["organization_metadata"]["human_reachable_hours"]) ? (
                        organization_data["organization_metadata"]["human_reachable_hours"].map((day, day_index) => (
                          <div key={day_index} className="flex flex-col space-y-2">
                            {/* Display fields */}
                            <div className="flex items-center space-x-4">
                              
                              {/* Day */}
                              <div className="">
                                {day_map[day.day]}
                              </div>
  
                              {/* Formatted windows */}
                              {day.windows.length === 0 ? (
                                // The business is closed
                                <div>
                                  Closed
                                </div>
                              ) : (day.windows.length === 1) && (day.windows[0][0] === "0000") && (day.windows[0][1] === "2400") ? (
                                // The business is closed
                                <div>
                                  Open 24 hours
                                </div>
                              ) : (
                                // Business is open
                                day.windows.map((window, window_index) => (
                                  <div key={window_index} className="flex items-center space-x-1">
  
                                    {/* Start time */}
                                    <div className="">
                                      {format_time(window[0])}
                                    </div>
                                    
                                    <div className="">-</div>
  
                                    {/* End time */}
                                    <div className="">
                                      {format_time(window[1])}
                                    </div>
  
                                    {/* Comma */}
                                    {window_index < (day.windows.length - 1) ? (
                                      <div>,</div>
                                    ) : (
                                      <></>
                                    )}
  
                                  </div>
                                ))
                              )}
  
                            </div>
                          </div> 
                        ))
                      ) : (
                        <div>Human reachable hours are the same as business hours</div>
                      )}

                    </div>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
              </dd>
            </div>

            {/* Website URL */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Website URL</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    <Link to={organization_data["organization_metadata"]["website_url"]} target="_blank" className="text-blue-600" >{organization_data["organization_metadata"]["website_url"]}</Link>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                <Link to="scan/website-url" className="font-semibold text-blue-600 hover:text-blue-500">
                  Update & scan
                </Link>
              </dd>
            </div>

            {/* Google Maps URL */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Google Maps URL</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    <Link to={organization_data["organization_metadata"]["google_maps_url"]} target="_blank" className="text-blue-600" >{organization_data["organization_metadata"]["google_maps_url"]}</Link>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                <Link to="scan/google-maps-url" className="font-semibold text-blue-600 hover:text-blue-500">
                  Update & scan
                </Link>
              </dd>
            </div>

            {/* Website data */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Website data</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    // <div>{organization_data["organization_metadata"]["website_data"]}</div>
                    <ReactMarkdown>{organization_data["organization_metadata"]["website_data"]}</ReactMarkdown>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                <Link to="update/website-data" className="font-semibold text-blue-600 hover:text-blue-500">
                  Edit
                </Link>
              </dd>
            </div>

            {/* Google Maps data */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Google Maps data</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    // <div>{organization_data["organization_metadata"]["google_maps_data"]}</div>
                    <ReactMarkdown>{organization_data["organization_metadata"]["google_maps_data"]}</ReactMarkdown>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                <Link to="update/google-maps-data" className="font-semibold text-blue-600 hover:text-blue-500">
                  Edit
                </Link>
              </dd>
            </div>

            {/* Delete business */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-red-400">Delete business</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">To close the business account, please reach out to support@guavahq.com</div>
              </dd>
            </div>
          </dl>
        </div>

      </div>

      {/* Modals */}
      <GoogleMapsModal 
        is_open={google_maps_modal_is_open}
        set_is_open={set_google_maps_modal_is_open}
        set_refresh_variable={set_refresh_variable}
      />


    </div>
  )
}

export default OrganizationPage