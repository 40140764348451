
import { useEffect } from 'react'

import { useGlobal } from '../../contexts/GlobalContext'

import { get_text_blocks } from '../../libs/text'
import { privacy_data } from '../../data/texts'
// import Navbar from '../../components/Navbar'
import { mixpanel_client_track } from '../../libs/mixpanelClient'


const PrivacyPage = ({

} : {

}) => {

  // Global context
  const global_context = useGlobal()

  

  // Renders
  useEffect(() => {

    // Mixpanel tracking
    mixpanel_client_track("app_privacy_visited", global_context.user_id === "UNSET" ? null : global_context.user_id)
    
  }, [])

  return ( 
    <>
      {/* <Navbar /> */}
      <div className="absolute w-full flex flex-col justify-center items-center z-10">
        <div className="pt-36 pb-36 sm:pt-36 sm:pb-36">
          <div className="relative overflow-hidden bg-transparent py-16">
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg">
                <h1>
                  <span className="block text-center text-lg font-semibold text-gray-600">Guava</span>
                  <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                    Privacy Policy
                  </span>
                </h1>
                <p className="mt-8 text-xl text-gray-500">
                  Effective date: November 17th, 2024
                </p>
              </div>
              <div className="prose prose-lg prose-blue mx-auto mt-6 text-gray-500 whitespace-pre-wrap">
                {get_text_blocks(privacy_data)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPage