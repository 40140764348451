
import { useEffect } from 'react'


import { useGlobal, useGlobalUpdate } from '../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../contexts/ProtectedContext'

import { classNames } from '../../libs/helpers'
import { set as idb_set } from 'idb-keyval';

import { ResponsiveLogo } from '../../components/logo';


import { useLocation, useNavigate, Outlet, Link } from "react-router-dom"

import { browserName, isMobile, isDesktop } from "react-device-detect";

import {
  CreditCardIcon,
  UserCircleIcon,
  UserGroupIcon,
  ArrowRightOnRectangleIcon,
  BuildingStorefrontIcon,
  UserIcon
} from '@heroicons/react/24/outline'
import axios from 'axios'


const universal_dashboard_navigation = [
  { name: 'My Account', to: 'account', icon: UserIcon },
]

const privileged_dashboard_navigation = [
  { name: 'Business Settings', to: 'organization', icon: BuildingStorefrontIcon },
  { name: 'Agents', to: 'agents', icon: UserCircleIcon },
  { name: 'Users', to: 'users', icon: UserGroupIcon },
  { name: 'Plan & Billing', to: 'plan', icon: CreditCardIcon },
]





const DashboardPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // Location
  const location = useLocation()
  // const dashboard_route = location.pathname.slice(1).split('/')[1]
  const dashboard_route = location.pathname.split('/dashboard/')[1] || ''



  const logout = async () => {
    const delete_auth_res = await axios.delete(`/api/auth`)

    if (!delete_auth_res.data.success) {
      // TODO
      return
    }

    // Set session_token to UNSET
    await idb_set('session_token', "UNSET");

    global_update({
      ...global_context,
      authenticated: false,
      user_id: "UNSET",
      type: "UNSET"
    })

    // This doesn't appear to be necessary, but just having it here for safety and for code readability
    navigate("/login")
  }


  // Render - handle user view banner 
  useEffect(() => {

  }, [location])

  return (
    <div className="absolute w-full h-full flex flex-col justify-center items-center z-10">
      {isDesktop
      ? <div className="flex w-full min-h-full flex-1 flex-col justify-center">

          {/* Dashboard */}
          <div className="mx-0 max-w-7xl lg:flex h-full">

            {/* Left navigation */}
            {/* <div className="flex flex-col border-r py-4 px-4 bg-gray-50"> */}
            <div className="flex flex-col lg:fixed lg:top-0 lg:bottom-0 lg:left-0 border-r py-4 px-4 bg-gray-50">
              
              {/* Logo */}
              <div className="flex">
                <Link to="/" className="my-1.5 py-1.5">
                  <ResponsiveLogo className="h-7" />
                </Link>
              </div>

              {/* Main nav */}
              <nav className="mt-4 flex lg:flex-col flex-1 justify-stretch lg:px-0">
                {/* Navigation  */}
                <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">

                  {/* Privileged navigation */}
                  {["owner", "admin"].includes(global_context.type)
                  ? <>
                    {privileged_dashboard_navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={`/dashboard/${item.to}`}
                          className={classNames(
                            item.to === dashboard_route
                              ? 'bg-gray-50 text-blue-600'
                              : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.to === dashboard_route ? 'text-blue-600' : 'text-gray-400 group-hover:text-blue-600',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </>
                  : <></>}

                  {/* Universal navigation */}
                  {universal_dashboard_navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={`/dashboard/${item.to}`}
                        className={classNames(
                          item.to === dashboard_route
                            ? 'bg-gray-50 text-blue-600'
                            : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.to === dashboard_route ? 'text-blue-600' : 'text-gray-400 group-hover:text-blue-600',
                            'h-6 w-6 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}

                </ul>

                {/* Logout */}
                <div className="lg:fixed right-6 lg:left-4 lg:bottom-6 text-sm font-semibold w-fit cursor-pointer">
                  <div
                    className="text-gray-700 hover:text-red-600 hover:bg-gray-50 group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                    onClick={logout}
                  >
                    <ArrowRightOnRectangleIcon className='text-gray-700 group-hover:text-red-600 h-6 w-6 shrink-0' />
                    <div className="">Logout</div>
                  </div>
                </div>
              </nav>
            </div>

            {/* Main */}
            {/* <main className="w-full px-12"> */}
            <main className="lg:ml-60 w-full px-12">
              <Outlet />
            </main>
          </div>

          
        </div>
      : <div className="flex min-h-full flex-col bg-transparent pt-16 pb-12">
          <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
            <div className="py-16">
              <div className="text-center">
                <p className="text-base font-semibold text-gray-600">Error</p>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Unsupported access.</h1>
                <p className="mt-2 text-base text-gray-500">This page can only be accessed from a desktop.</p>
                <div className="mt-6">
                  <Link to='/'>
                    <span className="text-base font-medium text-gray-600 hover:text-gray-500">
                      Go back home
                      <span aria-hidden="true"> &rarr;</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </main>
          <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
            <nav className="flex justify-center space-x-4">
              <Link to="mailto:support@guavahq.com" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Contact Support
              </Link>
            </nav>
          </footer>
        </div>
      }
    </div>
  )
}

export default DashboardPage