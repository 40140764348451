
import { v4 as uuidv4 } from 'uuid'
import { campaign_user_property_placeholder_map, user_property_map } from './formats'



export const sleep = function (duration: number) {
	return new Promise<void>(resolve => {
		setTimeout(() => {
			resolve()
		}, duration * 1000)
	})
}

export const handle_enter_keydown = (e: any, callback: any) => {
	//it triggers by pressing the enter key
	if (e.key === 'Enter') {
		callback();
	}
}




export const get_duplicates_in_array = (array) => {
  const unique_elements = new Set();
  const duplicates = array.filter(item => {
    if (unique_elements.has(item)) {
      return true;
    }
    unique_elements.add(item);
    return false;
  });
  return duplicates;
}







export const filter_regex = /\{\{(.+?)\}\}:\{\{(.+?)\}\}:\{\{(.+?)\}\}/








export const simulation_objective_description_map = {
  "open_link": "To get the user to click on the objective link.",
  "open_link_submit_website": "To get the user to open a website and submit info",
  "open_attachment": "To get the user to open an attachment",
  "reply_info": "To get the user to disclose a certain piece of information.",
}

export const get_prompt_text = (campaign, organization_name) => {
  return `### Instructions ###
  You are an expert coder who specializes in writing full HTML code for emails. You are going to have an email conversation with a user and the way you converse with the user is via the full email HTML code as each message. You will begin the conversation with a full email HTML code message, and then the user will respond with their full email HTML code message, and so on. Every message will begin with <html> and end with </html>. Here are the specifications as well as your objective for the conversation, and your job is to lead the conversation in a way such that you can achieve the objective.
  
  ### Specifications ###
  Your email display name: ${campaign.campaign_metadata.simulation_display_name}
  Instructions: ${campaign.campaign_metadata.simulation_multi_message_instructions}
  Your objective: ${simulation_objective_description_map[campaign.campaign_metadata.simulation_objective]}

  ${(() => {
    switch(campaign.campaign_metadata.simulation_objective){
      case "open_link": {
        return `Objective link: {{objective_link}}`
      }
      case "open_link_submit_website": {
        return `Website link: ${campaign.campaign_metadata.simulation_website_url}`
      }
      case "open_attachment": {
        return `Attachment: ${campaign.campaign_metadata.simulation_attachment_name}.${campaign.campaign_metadata.simulation_attachment_extension}`
      }
      case "reply_info": {
        return `Information to try to elicit from the user: ${campaign.campaign_metadata.simulation_reply_info_objective}`
      }
      case "_____": {
        return `______`
      }
      default: {
        return ""
      }
    }
  })()}
  
  ${(campaign.campaign_metadata.simulation_multi_message_excerpt !== "UNSET" && campaign.campaign_metadata.simulation_multi_message_excerpt !== "") ? `
    
  ### Reference email excerpt ###
  Use the following email excerpt as a reference for tone of voice:
  
  ${campaign.campaign_metadata.simulation_multi_message_excerpt}` : ""}

  ### User information ###
  User company: ${organization_name} 
  ${campaign.campaign_metadata.simulation_user_properties.map(property => `${user_property_map[property]}: ${campaign_user_property_placeholder_map[property]}`).join(`
  `)}
  ### Important notes ###
  - You must focus on building rapport with the user.
  - In the first full email HTML code message that you send, you must not prompt the user to take the action that will achieve your objective. No exceptions. In the first email HTML code message, you should work on building trust with the user. 
  - You should ask the user to take the objective action only when you decide that enough rapport has been established with the user.
  - Your message must only contain the full email HTML code (without any subject line), must include the body tag, and must begin with <html> and end with </html>.
  - Do not return it as a code block, by having backticks at the beginning and end.`
}


export const multi_message_user_count_limit = 20




export const default_business_hours = [
  { day: "monday", windows: [["0900", "1800"]]}, // Monday (9a-6p)
  { day: "tuesday", windows: [["0900", "1800"]]}, // Tuesday (9a-6p)
  { day: "wednesday", windows: [["0900", "1800"]]}, // Wednesday (9a-6p)
  { day: "thursday", windows: [["0900", "1800"]]}, // Thursday (9a-6p)
  { day: "friday", windows: [["0900", "1800"]]}, // Friday (9a-6p)
  { day: "saturday", windows: []}, // Saturday (closed)
  { day: "sunday", windows: []}, // Sunday (closed)
]

export const initial_business_hours = [
  { day: "monday", windows: []}, // Monday (closed)
  { day: "tuesday", windows: []}, // Tuesday (closed)
  { day: "wednesday", windows: []}, // Wednesday (closed)
  { day: "thursday", windows: []}, // Thursday (closed)
  { day: "friday", windows: []}, // Friday (closed)
  { day: "saturday", windows: []}, // Saturday (closed)
  { day: "sunday", windows: []}, // Sunday (closed)
]









export const hours_are_identical = (hours1, hours2) => {
  for (let i = 0; i < 7; i++) {
    // Check number of windows
    if (hours1[i].windows.length !== hours2[i].windows.length) {
      return false
    }

    // For each window, check start and end times
    for (let j = 0; j < hours1[i].windows.length; j++) {
      // Start time
      if (hours1[i].windows[j][0] !== hours2[i].windows[j][0]) {
        return false
      }
      // End time
      if (hours1[i].windows[j][1] !== hours2[i].windows[j][1]) {
        return false
      }
    }
  }
  return true
}




// Show success notification
export const show_notification = (protected_context, protected_update, type, header, body) => {
  protected_update({
    ...protected_context,
    notifications: [
      ...protected_context.notifications,
      {
        id: uuidv4(),
        type: type,
        header: header,
        body: body
      }
    ]
  })
}


export const arrays_have_same_elements = (array_one, array_two) => {
  const array_two_set = new Set(array_two)
  return new Set(array_one).size === new Set(array_two).size && [...new Set(array_one)].every(e => array_two_set.has(e))
}



export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ")
}



export const timestamp_to_local_datetime = (timestamp) => {
  const date = new Date(timestamp);

  const local_date = [
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, '0'), // Months are 0-indexed
    date.getDate().toString().padStart(2, '0')
  ].join('-')

  const local_time = [
    date.getHours().toString().padStart(2, '0'),
    date.getMinutes().toString().padStart(2, '0')
  ].join(':')

  return [local_date, local_time];
}



export const local_datetime_to_timestamp = (local_date, local_time) => {
  const [year, month, day] = local_date.split('-').map(Number);
  const [hours, minutes] = local_time.split(':').map(Number);

  // const date = new Date(year, month - 1, day, hours, minutes); // Months are 0-indexed
  // return Date.UTC(year, month - 1, day, hours, minutes);

  // Create a date object in local timezone and convert to UTC timestamp
  const local_date_object = new Date(year, month - 1, day, hours, minutes); // Months are 0-indexed
  // return local_date_object.getTime() - (local_date_object.getTimezoneOffset() * 60 * 1000);
  return local_date_object.getTime()
}