

// **************************************
// Options
// **************************************

export const user_type_options = [
  "end_user",
  "admin",
  "owner"
]

export const campaign_type_options = [
  "simulation",
  "training"
]

export const campaign_delivery_type_options = [
  "window",
  "same",
]

export const simulation_program_status_options = [
  "none",
  "draft",
  "active",
  "reported",
  "failed",
  "terminated",
]

export const training_program_status_options = [
  "none",
  "draft",
  "active",
  "completed",
  "terminated",
]


// export const department_options = [
//   "C-Suite",
//   "IT/Security",
//   "Sales",
//   "Marketing",
//   "Engineering",
//   "Procurement",
//   "Finance",
//   "Legal",
//   "Accounting",
//   "Product",
//   "Design",
//   "Human Resources",
//   "Public Relations",
//   "Investor Relations",
//   "Business Development",
//   "Operations",
//   "Quality Assurance",
//   "Research & Development",
//   "Other",
// ]

// export const position_options = [
//   "CISO",
//   "CTO",
//   "CEO",
//   "COO",
//   "Security Engineer",
//   "IT Engineer",
//   "Product Manager",
//   "Software Engineer",
//   "Other",
// ]



export const user_property_options = [
  // Profile properties
  "first_name", // user_doc.user_metadata.first_name
  "last_name", // user_doc.user_metadata.last_name
  "email_address", // user_doc.email_address
]

export const required_user_property_options = [
  "first_name",
  "last_name",
  "email_address",
  "position",
]


export const editable_user_property_options = [
  "first_name",
  "last_name", 
  "email_address",
  "position", 
  "department", 
  "division", 
  "organization", 
  "manager_id", 
  "manager_name", 
  "preferred_language",
  "city", 
  "country", 
  "locale", 
  "timezone", 
  "phone_number",
  "social_linkedin_url",
  "social_instagram_url", 
  "social_facebook_url", 
]









export const agent_property_options = [
  // Profile properties
  "name", // agent_doc.user_metadata.name
  "email_address", // agent_doc.email_address
  "phone_number", // agent_doc.phone_number
  "position", // agent_doc.user_metadata.email_address
]



export const query_by_options = [
  "area_code",
  "city",
  "state",
  "postal_code"
]






export const receptionist_action_options = [
  "schedule_appointment",
]











export const campaign_user_property_options = [
  "first_name",
  "last_name", 
  "email_address",
  "position", 
  "department", 
  "division", 
  "organization", 
  "manager_id",
  "manager_name", 
  "preferred_language",
  "city", 
  "country", 
  "locale", 
  "timezone", 
  "phone_number",
  "social_linkedin_url",
  "social_instagram_url", 
  "social_facebook_url", 
]






export const program_property_options = [
  "first_name", // program_doc.user_metadata.first_name
  "last_name", // program_doc.user_metadata.last_name
  "email_address", // program_doc.email_address
  "simulation_status", // program_doc.simulation_status
  "training_status", // program_doc.training_status
  "initial_email_is_sent", // (complex logic)
  "initial_email_status", // (complex logic)
  "open_email_is_done", // "open_email" in program_doc.actions
  "report_email_is_done", // "report_email" in program_doc.actions
  "open_link_is_done", // "open_link" in program_doc.actions
  "submit_website_is_done", // "submit_website" in program_doc.actions
  "reply_email_is_done", // "reply_email" in program_doc.actions
  "reply_email_objective_is_done", // "reply_email_objective" in program_doc.actions
  "reply_message_is_done", // "reply_message" in program_doc.actions
  "reply_message_objective_is_done", // "reply_message_objective" in program_doc.actions
  "program_created_at", // program_doc.program_created_at
  "name", // program_doc.program_metadata.name
]



export const job_status_options = [
  "none",
  "email_delivered",
  "email_deferred",
  "email_bounced",
  "message_delivered",
]





export const filter_options = [
  "includes_text",
  "equals_text",
  "equals_option",
  "before_date",
  "after_date",
  "equals_number",
  "lte_number",
  "gte_number",
  "lt_number",
  "gt_number",
]



export const provision_type_options = [
  "disabled",
  "native",
  "scim",
]

export const authentication_type_options = [
  "disabled",
  "password",
  "sso",
]


export const authentication_sso_method_options = [
  "google_oauth",
]


export const receptionist_mode_options = [
  "front_desk",
  "after_hours"
]





export const simulation_single_send_objective_options = [
  "open_link",
  "open_link_submit_website",
  // "open_attachment",
]

export const simulation_multi_message_objective_options = [
  "open_link",
  "open_link_submit_website",
  // "open_attachment", 
  "reply_info", 
]


export const simulation_default_domain_options = [
  "admin-settings.com",
  // "google-homepage.com"
]


export const training_module_options = [
  "ransomware",
  "malware",
  "phishing",
  "spear_phishing",
  // "smishing",
  // "vishing",
  // "deep_fake",
  // "business_email_compromise",
  // "digital_footprint",
  // "linkedin_footprint",
  // "ceo_fraud",
  // "passwords",
  // "mfa",
  // "personal_data",
  // "mobile_phone",
  // "tailgating",
  // "out_of_office",
  // "privacy_by_design",
  "soc2_training",
  "iso27001_training",
  "hipaa_training",
  "pci_training",
  "gdpr_training", 
  "ccpa_training",
]



export const simulation_attachment_extension_options = [
  "pdf",
  "exe"
]



