import { useEffect, useState, useRef } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../contexts/ProtectedContext'

import { CheckCircleIcon, ExclamationTriangleIcon  } from '@heroicons/react/20/solid'
import { ArrowLongLeftIcon, ChevronDownIcon, ChevronRightIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline'


import { Link, useLocation, useNavigate } from "react-router-dom"

import { auth_axios } from '../../../../../libs/authWeb';
import { handle_enter_keydown, show_notification, classNames, sleep, default_business_hours, hours_are_identical, initial_business_hours } from '../../../../../libs/helpers';
import { mixpanel_client_track } from '../../../../../libs/mixpanelClient'
import { day_map, format_time } from '../../../../../libs/formats'
import { Disclosure } from '@headlessui/react'






const OrganizationUpdateBusinessHoursPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()
  

  // Status message
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")
  
  // User input
  const [business_hours, set_business_hours] = useState([])
  const [human_reachable_hours, set_human_reachable_hours] = useState([])
  const [human_reachable_hours_are_tethered_to_business_hours, set_human_reachable_hours_are_tethered_to_business_hours] = useState(true)


  // Statuses
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [is_updated, set_is_updated] = useState(false)


  // Handle user input
  const handle_user_input = (type, value) => {

    const vars = {
      business_hours: business_hours,
      human_reachable_hours: human_reachable_hours
    }

    const fns = {
      set_business_hours: set_business_hours,
      set_human_reachable_hours: set_human_reachable_hours
    }

    switch(type) {
      case "window_start_time_hours": {

        // Variables
        let hh = value[3].replace(/\D/g, ''); // Remove non-numeric characters

        const updated_hours = [...vars[`${value[0]}_hours`]]
        let mm = updated_hours[value[1]].windows[value[2]][0].slice(2, 4)

        // Limit to two characters, using the last two
        if (hh.length > 2) {
          hh = hh.slice(-2); 
        }

        // Limit to 00-24 range
        if (parseInt(hh, 10) > 24) {
          hh = "24";
        }

        // If single digit, left-pad single digit with zero
        if (hh.length === 1) {
          hh = "0" + hh; 
        }

        // If hh is "24", set mm to "00"
        if (hh === "24") {
          mm = "00"
        } 

        // Update business_hours
        updated_hours[value[1]].windows[value[2]][0] = hh + mm;

        // Call fn
        fns[`set_${value[0]}_hours`](updated_hours)

        // If updating business and human_reachable_hours is tethered to business_hours, update human_reachable_hours too
        if (value[0] === "business" && human_reachable_hours_are_tethered_to_business_hours) {
          set_human_reachable_hours(JSON.parse(JSON.stringify(updated_hours)))
        }
  
        // Always break
        break
      }
      case "window_start_time_minutes": {

        // Variables
        const updated_hours = [...vars[`${value[0]}_hours`]]
        let hh = updated_hours[value[1]].windows[value[2]][0].slice(0, 2)

        let mm = value[3].replace(/\D/g, ''); // Remove non-numeric characters

        // Limit to two characters, using the last two
        if (mm.length > 2) {
          mm = mm.slice(-2); 
        }
      
        // Limit to 00-59 range
        if (parseInt(mm, 10) > 59) {
          mm = "00";
        } 

        // If single digit, left-pad single digit with zero
        if (mm.length === 1) {
          mm = "0" + mm;
        }

        // If hh is "24", set mm to "00"
        if (hh === "24") {
          mm = "00"
        } 

        // Update business_hours
        updated_hours[value[1]].windows[value[2]][0] = hh + mm;

        // Call fn
        fns[`set_${value[0]}_hours`](updated_hours)

        // If updating business and human_reachable_hours is tethered to business_hours, update human_reachable_hours too
        if (value[0] === "business" && human_reachable_hours_are_tethered_to_business_hours) {
          set_human_reachable_hours(JSON.parse(JSON.stringify(updated_hours)))
        }
  
        // Always break
        break
      }
      case "window_end_time_hours": {

        // Variables
        let hh = value[3].replace(/\D/g, ''); // Remove non-numeric characters

        const updated_hours = [...vars[`${value[0]}_hours`]]
        let mm = updated_hours[value[1]].windows[value[2]][1].slice(2, 4)

        // Limit to two characters, using the last two
        if (hh.length > 2) {
          hh = hh.slice(-2); 
        }

        // Limit to 00-24 range
        if (parseInt(hh, 10) > 24) {
          hh = "24";
        }
        else if (hh.length === 1) {
          hh = "0" + hh; // Left-pad single digit with zero
        }

        // If hh is "24", set mm to "00"
        if (hh === "24") {
          mm = "00"
        } 

        // Update business_hours
        updated_hours[value[1]].windows[value[2]][1] = hh + mm;

        // Call fn
        fns[`set_${value[0]}_hours`](updated_hours)

        // If updating business and human_reachable_hours is tethered to business_hours, update human_reachable_hours too
        if (value[0] === "business" && human_reachable_hours_are_tethered_to_business_hours) {
          set_human_reachable_hours(JSON.parse(JSON.stringify(updated_hours)))
        }
  
        // Always break
        break
      }
      case "window_end_time_minutes": {

        // Variables
        const updated_hours = [...vars[`${value[0]}_hours`]]
        let hh = updated_hours[value[1]].windows[value[2]][1].slice(0, 2)

        let mm = value[3].replace(/\D/g, ''); // Remove non-numeric characters

        // Limit to two characters, using the last two
        if (mm.length > 2) {
          mm = mm.slice(-2); 
        }
      
        // Limit to 00-59 range
        if (parseInt(mm, 10) > 59) {
          mm = "00";
        } 

        // If single digit, left-pad single digit with zero
        if (mm.length === 1) {
          mm = "0" + mm;
        }

        // If hh is "24", set mm to "00"
        if (hh === "24") {
          mm = "00"
        } 

        // Update business_hours
        updated_hours[value[1]].windows[value[2]][1] = hh + mm;

        // Call fn
        fns[`set_${value[0]}_hours`](updated_hours)

        // If updating business and human_reachable_hours is tethered to business_hours, update human_reachable_hours too
        if (value[0] === "business" && human_reachable_hours_are_tethered_to_business_hours) {
          set_human_reachable_hours(JSON.parse(JSON.stringify(updated_hours)))
        }
  
        // Always break
        break
      }
      case "open_24_hours": {

        // Variables
        const updated_hours = [...vars[`${value[0]}_hours`]]

        // Variables
        const checked = value[2]

        // 24 hours
        if (checked) {
          updated_hours[value[1]].windows = [["0000", "2400"]]
        }
        // Not 24 hours
        else {
          updated_hours[value[1]].windows = [["0900", "1800"]]
        }

        // Call fn
        fns[`set_${value[0]}_hours`](updated_hours)

        // If updating business and human_reachable_hours is tethered to business_hours, update human_reachable_hours too
        if (value[0] === "business" && human_reachable_hours_are_tethered_to_business_hours) {
          set_human_reachable_hours(JSON.parse(JSON.stringify(updated_hours)))
        }
  
        // Always break
        break
      }
      case "closed": {

        // Variables
        const updated_hours = [...vars[`${value[0]}_hours`]]

        // Variables
        const checked = value[2]

        // Closed hours
        if (checked) {
          updated_hours[value[1]].windows = []
        }
        // Not closed
        else {
          updated_hours[value[1]].windows = [["0900", "1800"]]
        }

        // Call fn
        fns[`set_${value[0]}_hours`](updated_hours)

        // If updating business and human_reachable_hours is tethered to business_hours, update human_reachable_hours too
        if (value[0] === "business" && human_reachable_hours_are_tethered_to_business_hours) {
          set_human_reachable_hours(JSON.parse(JSON.stringify(updated_hours)))
        }
  
        // Always break
        break
      }
      case "add_window": {

        // Variables
        const updated_hours = [...vars[`${value[0]}_hours`]]

        // Figure out the window to add
        const window_to_add = ["0900", "1800"]

        // Set the start time same as previous window's end time
        if (updated_hours[value[1]].windows.length > 0) {
          window_to_add[0] = updated_hours[value[1]].windows[value[2]][1]
        }

        // If the start time is later than 1800, set end time to 2400
        if (window_to_add[0] > "1800") {
          window_to_add[1] = "2400"
        }

        // Add hours
        updated_hours[value[1]].windows.splice(value[2] + 1, 0, window_to_add)

        // Call fn
        fns[`set_${value[0]}_hours`](updated_hours)

        // If updating business and human_reachable_hours is tethered to business_hours, update human_reachable_hours too
        if (value[0] === "business" && human_reachable_hours_are_tethered_to_business_hours) {
          set_human_reachable_hours(JSON.parse(JSON.stringify(updated_hours)))
        }
  
        // Always break
        break
      }
      case "remove_window": {

        // Variables
        const updated_hours = [...vars[`${value[0]}_hours`]]

        // Add hours
        updated_hours[value[1]].windows.splice(value[2], 1)

        // Call fn
        fns[`set_${value[0]}_hours`](updated_hours)

        // If updating business and human_reachable_hours is tethered to business_hours, update human_reachable_hours too
        if (value[0] === "business" && human_reachable_hours_are_tethered_to_business_hours) {
          set_human_reachable_hours(JSON.parse(JSON.stringify(updated_hours)))
        }
  
        // Always break
        break
      }
      case "human_reachable_hours_are_tethered_to_business_hours": {

        // Call fn
        set_human_reachable_hours_are_tethered_to_business_hours(value)

        // Override the human_reachable_hours to that of business_hours
        set_human_reachable_hours(JSON.parse(JSON.stringify(business_hours)))
  
        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }


  const get_organization = async () => {

    set_is_awaiting(true)

    // Execute get org
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    set_business_hours(get_org_res.data.organization_data.organization_metadata.business_hours || default_business_hours)
    set_human_reachable_hours(get_org_res.data.organization_data.organization_metadata.human_reachable_hours || default_business_hours)
    set_human_reachable_hours_are_tethered_to_business_hours(hours_are_identical(get_org_res.data.organization_data.organization_metadata.business_hours, get_org_res.data.organization_data.organization_metadata.human_reachable_hours))
    set_is_awaiting(false)
  }



  // Update name
  const update_business_hours = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Make sure business_hours is not identical to initial_business_hours
    if (hours_are_identical(business_hours, initial_business_hours)) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Business hours: please set the business hours")

      // End of the line
      return
    }

    // Make sure human_reachable_hours is not identical to initial_business_hours
    if (hours_are_identical(human_reachable_hours, initial_business_hours)) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Human reachable hours: please set the hours that a human will be reachable at")

      // End of the line
      return
    }

    // Iterate over business_hours
    for (const day of business_hours) {

      let latest_time = ""

      for (const window of day.windows) {

        // Make sure start time is later than last end time
        if (window[0] <= latest_time) {
          set_is_awaiting(false)

          // Show error message
          set_error_message("Business hours: block start time must be greater than previous block's end time")

          // End of the line
          return
        }

        // Make sure end time is later than start time
        if (window[1] <= window[0]) {
          set_is_awaiting(false)

          // Show error message
          set_error_message("Business hours: end time must be greater than start time.")

          // End of the line
          return
        }

        // Update latest_time
        latest_time = window[1]
      }
    }

    // Iterate over human_reachable_hours
    for (const day of human_reachable_hours) {

      let latest_time = ""

      for (const window of day.windows) {

        // Make sure start time is later than last end time
        if (window[0] <= latest_time) {
          set_is_awaiting(false)

          // Show error message
          set_error_message("Human reachable hours: block start time must be greater than previous block's end time")

          // End of the line
          return
        }

        // Make sure end time is later than start time
        if (window[1] <= window[0]) {
          set_is_awaiting(false)

          // Show error message
          set_error_message("Human reachable hours: end time must be greater than start time.")

          // End of the line
          return
        }

        // Update latest_time
        latest_time = window[1]
      }
    }

    // END OF USER INPUT CHECK

    // Execute update name
    const put_org_name_res = await auth_axios.put(`/api/organizations/business-hours`, {
      business_hours: business_hours,
      human_reachable_hours: human_reachable_hours,
    })

    if (!put_org_name_res.data.success) {
      switch (put_org_name_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (put_org_name_res.data.status) {
      case "SUCCESS": {
        set_is_updated(true)
        set_is_awaiting(false)
        set_success_message("Business hours have been updated")

        // Always break
        break
      }
      case "FAILURE": {
        // alert("Error occurred while updating organization name. Please try again")
        show_notification(protected_context, protected_update, "error", "Error", "Error occurred while updating business hours. Please try again")

        navigate(`/dashboard/account`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

  }

  // Renders 
  useEffect(() => {
    
    // Get organization data
    get_organization()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_organization_update_business_hours_visited", global_context.user_id)
    
  }, [])


  return (
    <div className="px-4 sm:px-6 lg:flex-auto lg:px-0 py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>

          <Link to="/dashboard/organization" className="text-gray-500 hover:text-gray-700 font-medium flex text-sm items-center space-x-2 ">
            <ArrowLongLeftIcon className="h-6 w-6" />
            <div>Back</div>
          </Link>

          <h1 className="mt-12 text-base font-semibold leading-7 text-gray-900">Update business hours</h1>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Update business hours & human-reachable hours
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex sm:flex-col max-w-[400px] justify-center items-center">
              <div className="w-full space-y-8">

                {/* Business hours */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Business hours
                  </label>
                  <div className="mt-4">
                    {is_awaiting ? (
                      <ReactLoading
                        type='spokes'
                        color='#343D46'
                        height={20}
                        width={20}
                      />
                    ) : (
                      <div className="space-y-4">

                        {/* Each day */}
                        {business_hours.map((day, day_index) => (
                          <Disclosure key={day_index}  as="div" className="flex flex-col space-y-2">
                            {({ open }) => (
                              <>
                                {/* Display fields */}
                                <Disclosure.Button className="flex items-center space-x-4">

                                  {/* Day */}
                                  <div className="">
                                    {day_map[day.day]}
                                  </div>

                                  {/* Formatted windows */}
                                  {day.windows.length === 0 ? (
                                    // The business is closed
                                    <div>
                                      Closed
                                    </div>
                                  ) : (day.windows.length === 1) && (day.windows[0][0] === "0000") && (day.windows[0][1] === "2400") ? (
                                    // The business is closed
                                    <div>
                                      Open 24 hours
                                    </div>
                                  ) : (
                                    // Business is open
                                    day.windows.map((window, window_index) => (
                                      <div key={window_index} className="flex items-center space-x-1">

                                        {/* Start time */}
                                        <div className="">
                                          {format_time(window[0])}
                                        </div>
                                        
                                        <div className="">-</div>

                                        {/* End time */}
                                        <div className="">
                                          {format_time(window[1])}
                                        </div>

                                        {/* Comma */}
                                        {window_index < (day.windows.length - 1) ? (
                                          <div>,</div>
                                        ) : (
                                          <></>
                                        )}

                                      </div>
                                    ))
                                  )}

                                  {/* Chevron */}
                                  <span className="ml-6 flex h-7 items-center">
                                    {open ? (
                                      <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                                    ) : (
                                      <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                                    )}
                                  </span>
                                </Disclosure.Button>

                                {/* Edit fields */}
                                <Disclosure.Panel as="div" className="space-y-2">

                                  {/* Checkboxes */}
                                  <div className="flex space-x-4">
                                    {/* Open 24 hours */}
                                    <div className="flex items-center space-x-2">
                                      <input
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                        checked={(business_hours[day_index].windows.length === 1) && (business_hours[day_index].windows[0][0] === "0000") && (business_hours[day_index].windows[0][1] === "2400")}
                                        onChange={(e) => handle_user_input("open_24_hours", ["business", day_index, e.target.checked])}
                                        onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
                                      />
                                      <div>Open 24 hours</div>
                                    </div>

                                    {/* Closed */}
                                    <div className="flex items-center space-x-2">
                                      <input
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                        checked={business_hours[day_index].windows.length === 0}
                                        onChange={(e) => handle_user_input("closed", ["business", day_index, e.target.checked])}
                                        onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
                                      />
                                      <div>Closed</div>
                                      {/* Add button if closed */}
                                      {business_hours[day_index].windows.length === 0 ? (
                                        <div 
                                          className="rounded-3xl border p-1 cursor-pointer border-gray-300 hover:border-blue-600 group"
                                          onClick={() => handle_user_input("add_window", ["business", day_index, -1])} 
                                        >
                                          <PlusIcon className="h-4 w-4 text-gray-400 group-hover:text-blue-600" />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  

                                  {/* Windows */}
                                  <div className="flex flex-col space-y-4">
                                    {day.windows.map((window, window_index) => (
                                      <div key={window_index} className="flex items-center space-x-2">

                                        {/* Window */}
                                        <div className="flex items-center space-x-2">
                                          {/* Start time */}
                                          <div className="flex items-center space-x-1">
                                            {/* Start time hours */}
                                            <input
                                              type="tel"
                                              required
                                              className="block w-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                              placeholder="hh"
                                              value={window[0].slice(0, 2)}
                                              onChange={(e) => handle_user_input("window_start_time_hours", ["business", day_index, window_index, e.target.value])}
                                              disabled={is_awaiting}
                                            />

                                            <div className="">:</div>

                                            {/* Start time minutes */}
                                            <input
                                              type="tel"
                                              required
                                              className="block w-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                              placeholder="mm"
                                              value={window[0].slice(2, 4)}
                                              onChange={(e) => handle_user_input("window_start_time_minutes", ["business", day_index, window_index, e.target.value])}
                                              disabled={is_awaiting}
                                            />
                                          </div>
                                          
                                          {/* Dash */}
                                          <div className="">-</div>

                                          {/* End time */}
                                          <div className="flex items-center space-x-1">
                                            {/* End time hours */}
                                            <input
                                              type="tel"
                                              required
                                              className="block w-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                              placeholder="hh"
                                              value={window[1].slice(0, 2)}
                                              onChange={(e) => handle_user_input("window_end_time_hours", ["business", day_index, window_index, e.target.value])}
                                              disabled={is_awaiting}
                                            />

                                            <div className="">:</div>

                                            {/* End time minutes */}
                                            <input
                                              type="tel"
                                              required
                                              className="block w-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                              placeholder="mm"
                                              value={window[1].slice(2, 4)}
                                              onChange={(e) => handle_user_input("window_end_time_minutes", ["business", day_index, window_index, e.target.value])}
                                              disabled={is_awaiting}
                                            />
                                          </div>
                                        </div>

                                        
                                        {/* Add / remove buttons */}
                                        <div className="flex items-center space-x-2">

                                          {/* Add button */}
                                          <div 
                                            className="rounded-3xl border p-1 cursor-pointer border-gray-300 hover:border-blue-600 group"
                                            onClick={() => handle_user_input("add_window", ["business", day_index, window_index])} 
                                          >
                                            <PlusIcon className="h-4 w-4 text-gray-400 group-hover:text-blue-600" />
                                          </div>

                                          {/* Remove button */}
                                          <div 
                                            className="rounded-3xl border p-1 cursor-pointer border-gray-300 hover:border-red-600 group"
                                            onClick={() => handle_user_input("remove_window", ["business", day_index, window_index])} 
                                          >
                                            <MinusIcon className="h-4 w-4 text-gray-400 group-hover:text-red-600" />
                                          </div>
                                        </div>
                                        

                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure> 

                          
                        ))}

                        {/* Tethered */}
                        <div className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                            checked={human_reachable_hours_are_tethered_to_business_hours}
                            onChange={(e) => handle_user_input("human_reachable_hours_are_tethered_to_business_hours", e.target.checked)}
                            onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
                          />
                          <div>Human reachable hours are the same as business hours</div>
                        </div>

                      </div>
                    )}
                  </div>
                </div>
                
                {/* Human reachable hours */}
                {!human_reachable_hours_are_tethered_to_business_hours ? (
                  <div className="">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Human reachable hours
                    </label>
                    <div className="mt-4">
                      {is_awaiting ? (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />
                      ) : (
                        <div className="space-y-4">

                          {/* Each day */}
                          {human_reachable_hours.map((day, day_index) => (
                            <Disclosure key={day_index}  as="div" className="flex flex-col space-y-2">
                              {({ open }) => (
                                <>
                                  {/* Display fields */}
                                  <Disclosure.Button className="flex items-center space-x-4">

                                    {/* Day */}
                                    <div className="">
                                      {day_map[day.day]}
                                    </div>

                                    {/* Formatted windows */}
                                    {day.windows.length === 0 ? (
                                      // The business is closed
                                      <div>
                                        Closed
                                      </div>
                                    ) : (day.windows.length === 1) && (day.windows[0][0] === "0000") && (day.windows[0][1] === "2400") ? (
                                      // The business is closed
                                      <div>
                                        Open 24 hours
                                      </div>
                                    ) : (
                                      // Business is open
                                      day.windows.map((window, window_index) => (
                                        <div key={window_index} className="flex items-center space-x-1">

                                          {/* Start time */}
                                          <div className="">
                                            {format_time(window[0])}
                                          </div>
                                          
                                          <div className="">-</div>

                                          {/* End time */}
                                          <div className="">
                                            {format_time(window[1])}
                                          </div>

                                          {/* Comma */}
                                          {window_index < (day.windows.length - 1) ? (
                                            <div>,</div>
                                          ) : (
                                            <></>
                                          )}

                                        </div>
                                      ))
                                    )}

                                    {/* Chevron */}
                                    <span className="ml-6 flex h-7 items-center">
                                      {open ? (
                                        <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                                      ) : (
                                        <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                                      )}
                                    </span>
                                  </Disclosure.Button>

                                  {/* Edit fields */}
                                  <Disclosure.Panel as="div" className="space-y-2">

                                    {/* Checkboxes */}
                                    <div className="flex space-x-4">
                                      {/* Open 24 hours */}
                                      <div className="flex items-center space-x-2">
                                        <input
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                          checked={(human_reachable_hours[day_index].windows.length === 1) && (human_reachable_hours[day_index].windows[0][0] === "0000") && (human_reachable_hours[day_index].windows[0][1] === "2400")}
                                          onChange={(e) => handle_user_input("open_24_hours", ["human_reachable", day_index, e.target.checked])}
                                          onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
                                        />
                                        <div>Open 24 hours</div>
                                      </div>

                                      {/* Closed */}
                                      <div className="flex items-center space-x-2">
                                        <input
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                          checked={human_reachable_hours[day_index].windows.length === 0}
                                          onChange={(e) => handle_user_input("closed", ["human_reachable", day_index, e.target.checked])}
                                          onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
                                        />
                                        <div>Closed</div>
                                        {/* Add button if closed */}
                                        {human_reachable_hours[day_index].windows.length === 0 ? (
                                          <div 
                                            className="rounded-3xl border p-1 cursor-pointer border-gray-300 hover:border-blue-600 group"
                                            onClick={() => handle_user_input("add_window", ["human_reachable", day_index, -1])} 
                                          >
                                            <PlusIcon className="h-4 w-4 text-gray-400 group-hover:text-blue-600" />
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                    

                                    {/* Windows */}
                                    <div className="flex flex-col space-y-4">
                                      {day.windows.map((window, window_index) => (
                                        <div key={window_index} className="flex items-center space-x-2">

                                          {/* Window */}
                                          <div className="flex items-center space-x-2">
                                            {/* Start time */}
                                            <div className="flex items-center space-x-1">
                                              {/* Start time hours */}
                                              <input
                                                type="tel"
                                                required
                                                className="block w-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                placeholder="hh"
                                                value={window[0].slice(0, 2)}
                                                onChange={(e) => handle_user_input("window_start_time_hours", ["human_reachable", day_index, window_index, e.target.value])}
                                                disabled={is_awaiting}
                                              />

                                              <div className="">:</div>

                                              {/* Start time minutes */}
                                              <input
                                                type="tel"
                                                required
                                                className="block w-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                placeholder="mm"
                                                value={window[0].slice(2, 4)}
                                                onChange={(e) => handle_user_input("window_start_time_minutes", ["human_reachable", day_index, window_index, e.target.value])}
                                                disabled={is_awaiting}
                                              />
                                            </div>
                                            
                                            {/* Dash */}
                                            <div className="">-</div>

                                            {/* End time */}
                                            <div className="flex items-center space-x-1">
                                              {/* End time hours */}
                                              <input
                                                type="tel"
                                                required
                                                className="block w-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                placeholder="hh"
                                                value={window[1].slice(0, 2)}
                                                onChange={(e) => handle_user_input("window_end_time_hours", ["human_reachable", day_index, window_index, e.target.value])}
                                                disabled={is_awaiting}
                                              />

                                              <div className="">:</div>

                                              {/* End time minutes */}
                                              <input
                                                type="tel"
                                                required
                                                className="block w-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                placeholder="mm"
                                                value={window[1].slice(2, 4)}
                                                onChange={(e) => handle_user_input("window_end_time_minutes", ["human_reachable", day_index, window_index, e.target.value])}
                                                disabled={is_awaiting}
                                              />
                                            </div>
                                          </div>

                                          
                                          {/* Add / remove buttons */}
                                          <div className="flex items-center space-x-2">

                                            {/* Add button */}
                                            <div 
                                              className="rounded-3xl border p-1 cursor-pointer border-gray-300 hover:border-blue-600 group"
                                              onClick={() => handle_user_input("add_window", ["human_reachable", day_index, window_index])} 
                                            >
                                              <PlusIcon className="h-4 w-4 text-gray-400 group-hover:text-blue-600" />
                                            </div>

                                            {/* Remove button */}
                                            <div 
                                              className="rounded-3xl border p-1 cursor-pointer border-gray-300 hover:border-red-600 group"
                                              onClick={() => handle_user_input("remove_window", ["human_reachable", day_index, window_index])} 
                                            >
                                              <MinusIcon className="h-4 w-4 text-gray-400 group-hover:text-red-600" />
                                            </div>
                                          </div>
                                          

                                        </div>
                                      ))}
                                    </div>
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure> 
                          ))}

                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {error_message
                ? <div className="mt-6 flex space-x-2 items-start">
                    <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                    <div className="text-sm font-medium text-red-400">{error_message}</div>
                  </div>
                : <></>}
                {is_updated && success_message
                ? <div className="mt-6 flex space-x-2 items-start">
                    <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
                    <div className="text-sm font-medium text-green-600">{success_message}</div>
                  </div>
                : <></>}
                
                <button 
                  className={classNames(
                    is_awaiting || is_updated ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                    'mt-6 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                  )}
                  onClick={update_business_hours}
                  disabled={is_awaiting || is_updated}
                >
                  {!is_awaiting
                  ? <span>Update</span>
                  :<ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />}
                </button> 
              </div>
            </div>
          </dl>
        </div>

      </div>
    </div>
  )
}

export default OrganizationUpdateBusinessHoursPage