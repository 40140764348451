import { useEffect, useState, useRef } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../contexts/ProtectedContext'

import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'


import { Link, useLocation, useNavigate } from "react-router-dom"

import { auth_axios } from '../../../../../libs/authWeb';
import { handle_enter_keydown, show_notification, classNames, sleep } from '../../../../../libs/helpers';
import { mixpanel_client_track } from '../../../../../libs/mixpanelClient'
import { ArrowLongLeftIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Disclosure } from '@headlessui/react'






const OrganizationUpdateNamePage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()
  

  // Status message
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")
  
  // User input
  const [organization_name, set_organization_name] = useState("")
  const [organization_name_pronunciation, set_organization_name_pronunciation] = useState("")

  const organization_name_ref = useRef<any>(null);

  // Statuses
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [is_updated, set_is_updated] = useState(false)




  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "organization_name": {
        set_organization_name(value)
        set_organization_name_pronunciation(value)
  
        // Always break
        break
      }
      case "organization_name_pronunciation": {
        set_organization_name_pronunciation(value)
  
        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }


  const get_organization = async () => {

    set_is_awaiting(true)

    // Execute get org
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    set_organization_name(get_org_res.data.organization_data.organization_metadata.name || "")
    set_organization_name_pronunciation(get_org_res.data.organization_data.organization_metadata.name_pronunciation || "")
    set_is_awaiting(false)
  }



  // Update name
  const update_name = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate first name
    if (organization_name.length === 0) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Please enter the organization name")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Execute update name
    const put_org_name_res = await auth_axios.put(`/api/organizations/name`, {
      organization_name: organization_name,
      organization_name_pronunciation: organization_name_pronunciation,
    })

    if (!put_org_name_res.data.success) {
      switch (put_org_name_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (put_org_name_res.data.status) {
      case "SUCCESS": {
        set_is_updated(true)
        set_is_awaiting(false)
        set_success_message("Organization name has been updated")

        // Always break
        break
      }
      case "FAILURE": {
        // alert("Error occurred while updating organization name. Please try again")
        show_notification(protected_context, protected_update, "error", "Error", "Error occurred while updating organization name. Please try again")

        navigate(`/dashboard/account`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

  }

  // Renders 
  useEffect(() => {
    
    // Get organization data
    get_organization()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_organization_update_name_visited", global_context.user_id)
    
  }, [])


  return (
    <div className="px-4 sm:px-6 lg:flex-auto lg:px-0 py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>

          <Link to="/dashboard/organization" className="text-gray-500 hover:text-gray-700 font-medium flex text-sm items-center space-x-2 ">
            <ArrowLongLeftIcon className="h-6 w-6" />
            <div>Back</div>
          </Link>

          <h1 className="mt-12 text-base font-semibold leading-7 text-gray-900">Update name</h1>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Update business name
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex sm:flex-col max-w-[400px] justify-center items-center">
              <div className="w-full space-y-4">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Business name
                  </label>
                  <div className="mt-2">
                    {is_awaiting ? (
                      <ReactLoading
                        type='spokes'
                        color='#343D46'
                        height={20}
                        width={20}
                      />
                    ) : (
                      <input
                        type="text"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        ref={organization_name_ref}
                        value={organization_name}
                        onChange={(e) => handle_user_input("organization_name", e.target.value)}
                        disabled={is_awaiting || is_updated}
                      />
                    )}
                  </div>
                  <Disclosure as="div" className="mt-8 flex flex-col space-y-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex items-center space-x-2">
                          
                          {/* Text */}
                          <div className="text-sm font-medium leading-6 text-gray-900">
                            Pronunciation guide
                          </div>
                          
                          {/* Chevron */}
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                            ) : (
                              <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>

                        <Disclosure.Panel as="div" className="">
                          <input
                            type="text"
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            value={organization_name_pronunciation}
                            onChange={(e) => handle_user_input("organization_name_pronunciation", e.target.value)}
                            disabled={is_awaiting || is_updated}
                          />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure> 
                </div>

                {error_message
                ? <div className="mt-6 flex space-x-2 items-start">
                    <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                    <div className="text-sm font-medium text-red-400">{error_message}</div>
                  </div>
                : <></>}
                {is_updated && success_message
                ? <div className="mt-6 flex space-x-2 items-start">
                    <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
                    <div className="text-sm font-medium text-green-600">{success_message}</div>
                  </div>
                : <></>}
                
                <button 
                  className={classNames(
                    is_awaiting || is_updated ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                    'mt-6 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                  )}
                  onClick={update_name}
                  disabled={is_awaiting || is_updated}
                >
                  {!is_awaiting
                  ? <span>Update</span>
                  :<ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />}
                </button> 
              </div>
            </div>
          </dl>
        </div>

      </div>
    </div>
  )
}

export default OrganizationUpdateNamePage