import { authentication_sso_method_options, authentication_type_options, simulation_program_status_options, training_program_status_options, provision_type_options, user_type_options, job_status_options } from "./options";





// **************************************
// Dates
// **************************************


export const get_date_string = (date) => {
  const date_formatter = Intl.DateTimeFormat("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeStyle: "long",
    dateStyle: "long"
  });
  const date_string = date_formatter.format(new Date(date))

  return date_string
}

export const get_date_string_short = (date) => {
  const date_formatter = Intl.DateTimeFormat("en-US", {
    dateStyle: "long"
  });
  const date_string = date_formatter.format(new Date(date))

  return date_string
}

export const get_time_left_in_trial = (user_created_at) => {

  const now_date = new Date();
  const user_created_date = new Date(user_created_at)

  // Calculate the end date by adding 14 days to the start date
  const trial_end_date = new Date(user_created_date);
  trial_end_date.setTime(user_created_date.getTime() + (1000 * 60 * 60 * 24 * 14)); // 14 days

  // Calculate the difference in time between the end date and the current date
  const time_difference = trial_end_date.getTime() - now_date.getTime()

  return time_difference
}

export const get_days_left_in_trial = (user_created_at) => {

  // Calculate the difference in time between the end date and the current date
  const time_difference = get_time_left_in_trial(user_created_at)

  // Convert the time difference to days
  const days_left = Math.ceil(time_difference / (1000 * 60 * 60 * 24));

  return days_left
}


// **************************************
// Organization & user data
// **************************************

export const user_type_map = {
  'end_user': 'User',
  'admin': 'Admin',
  'owner': 'Organization owner',
}



export const get_organization_status_pretty = (status: string) => {
  switch(status) {
    case "inactive": {
      return "Inactive"
    }
    case "active": {
      return "Active"
    }
    default: {
      return "error"
    }
  }
}







export const filter_type_display_map = {
  includes_text: "includes",
  equals_text: "equals",
  equals_option: "equals",
  before_date: "is before",
  after_date: "is after",
  equals_number: "equals",
  lte_number: "less than or equal to",
  gte_number: "greater than or equal to",
  lt_number: "less than",
  gt_number: "greater than",
}








export const user_property_map = {
  // Profile properties
  first_name: "First name", // user_doc.user_metadata.first_name
  last_name: "Last name", // user_doc.user_metadata.last_name
  email_address: "Email address", // user_doc.email_address
  position: "Position", // user_doc.user_metadata.position
}




export const campaign_user_property_placeholder_map = {
  first_name: "{{first_name}}", // user_doc.user_metadata.first_name
  last_name: "{{last_name}}", // user_doc.user_metadata.last_name
  email_address: "{{email_address}}", // user_doc.email_address
  position: "{{position}}", // user_doc.user_metadata.position
}

export const get_user_property_value = (user_doc, user_property, is_formatted) => {
  switch(user_property) {
    // Profile properties
    case "first_name": {
      return (user_doc.user_metadata.first_name && user_doc.user_metadata.first_name !== "UNSET") ? (user_doc.user_metadata.first_name) : "(Unspecified)"
    }
    case "last_name": {
      return (user_doc.user_metadata.last_name && user_doc.user_metadata.last_name !== "UNSET") ? (user_doc.user_metadata.last_name) : "(Unspecified)"
    }
    case "email_address": {
      return user_doc.email_address
    }
    case "position": {
      return (user_doc.user_metadata.position && user_doc.user_metadata.position !== "UNSET") ? (user_doc.user_metadata.position) : "(Unspecified)"
    }
    // Error
    default: {
      return "Undefined"
    }
  }
}

export const get_user_property_filters = (property) => {
  switch(property) {
    case "first_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "last_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "email_address": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "position": {
      return {
        equals_text: {},
        // equals_option: {
        //   options: position_options,
        //   format: (value) => { return value }
        // },
        includes_text: {}
      }
    }
    default: {
      return {}
    }
  }
}




// **************************************
// Agent data
// **************************************



export const agent_property_map = {
  // Profile properties
  name: "Name", // agent_doc.agent_metadata.name
  email_address: "Email address", // agent_doc.email_address
  phone_number: "Phone number", // agent_doc.phone_number
  position: "Position", // agent_doc.agent_metadata.position
}



export const get_agent_property_value = (agent_doc, agent_property, is_formatted) => {
  switch(agent_property) {
    // Profile properties
    case "name": {
      return (agent_doc.agent_metadata.name && agent_doc.agent_metadata.name !== "UNSET") ? (agent_doc.agent_metadata.name) : "(Unspecified)"
    }
    case "email_address": {
      return (agent_doc.email_address && agent_doc.email_address !== "UNSET") ? (agent_doc.email_address) : "(Unspecified)"
    }
    case "phone_number": {
      return format_phone_number(agent_doc.phone_number)
    }
    case "position": {
      return (agent_doc.agent_metadata.position && agent_doc.agent_metadata.position !== "UNSET") ? (agent_doc.agent_metadata.position) : "(Unspecified)"
    }
    // Error
    default: {
      return "Undefined"
    }
  }
}

export const get_agent_property_filters = (property) => {
  switch(property) {
    case "name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "email_address": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "phone_number": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "position": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    default: {
      return {}
    }
  }
}



// **************************************
// Miscellaneous
// **************************************

export const query_by_map = {
  area_code: "Area code", 
  city: "City",
  state: "State", 
  postal_code: "Zip code",
}



export const receptionist_mode_map = {
  'front_desk': 'Front desk mode',
  'after_hours': 'After hours mode',
}




// **************************************
// Authentication types
// **************************************

export const authentication_type_map = {
  'disabled': 'Disabled',
  'password': 'Password',
  'sso': 'Single Sign-On',
}

export const authentication_sso_method_map = {
  'google_oauth': 'Google OAuth',
}


// **************************************
// Provision types
// **************************************

export const provision_type_map = {
  'disabled': 'Disabled',
  'native': 'Dashboard',
  'scim': 'SCIM',
}





export const submit_website_field_map = {
  first_name: "First name",
  last_name: "Last name",
  email_address: "Email address",
  password: "Password",
  credit_card_number: "Credit card number",
  credit_card_month: "Credit card month",
  credit_card_year: "Credit card year",
  credit_card_cvv: "Credit card CVV",
  credit_card_zip_code: "Credit card zip code",
}








// **************************************
// Stripe
// **************************************

export const get_invoice_status = (amount, status) => {
  if (amount === 0) {
    switch (status) {
      case "paid": {
        return "Settled"
      }
      case "void": {
        return "Voided"
      }
      case "open": {
        return "Failed"
      }
      case "draft": {
        return "Upcoming"
      }
      default: {
        return "Error occurred"
      }
    }
  }
  // Payment amount is positive (debit)
  else if (amount > 0) {
    switch (status) {
      case "paid": {
        return "Paid"
      }
      case "void": {
        return "Voided"
      }
      case "open": {
        return "Failed"
      }
      case "draft": {
        return "Upcoming"
      }
      default: {
        return "Error occurred"
      }
    }
  }
  // Payment amount is negative (credit)
  else if (amount < 0) {
    switch (status) {
      case "paid": {
        return "Credited"
      }
      case "void": {
        return "Voided"
      }
      case "open": {
        return "Failed"
      }
      case "draft": {
        return "Upcoming"
      }
      default: {
        return "Error occurred"
      }
    }
  }
}


export const beautify_usd_amount = ( usd_amount ) => {

  let res = '';

  // Zero
  if (usd_amount === 0) {
    res = '$0'
  } 

  // Positive
  else if (usd_amount > 0) {

    const usd_amount__string = usd_amount.toString();

    if (usd_amount__string.length === 1) {
      res = `$0.0${usd_amount__string}`;
    }
    else {
      if (!(usd_amount__string.slice(0, usd_amount__string.length-2))) {
        res = `$0.${usd_amount__string.slice(usd_amount__string.length-2)}`;
      } else {
        res = `$${usd_amount__string.slice(0, usd_amount__string.length-2)}.${usd_amount__string.slice(usd_amount__string.length-2)}`;
      }
    }
  }

  // Negative
  else if (usd_amount < 0) {

    const abs_usd_amount = Math.abs(usd_amount)
    const abs_usd_amount__string = abs_usd_amount.toString();
    
    if (abs_usd_amount__string.length === 1) {
      res = `-$0.0${abs_usd_amount__string}`;
    }
    else {
      if (!(abs_usd_amount__string.slice(0, abs_usd_amount__string.length-2))) {
        res = `-$0.${abs_usd_amount__string.slice(abs_usd_amount__string.length-2)}`;
      } else {
        res = `-$${abs_usd_amount__string.slice(0, abs_usd_amount__string.length-2)}.${abs_usd_amount__string.slice(abs_usd_amount__string.length-2)}`;
      }
    }
  }


  return res;
}




export const format_phone_number = (phone_number) => {
  // Remove the +1 prefix for now
  const digits = phone_number.replace(/^\+1/, '');

  // Format as +1 (XXX) XXX-XXXX
  return `+1 (${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
};


export const format_time = (time_string) => {
  const hours = parseInt(time_string.slice(0, 2), 10);
  const minutes = parseInt(time_string.slice(2), 10);
  const date = new Date(0, 0, 0, hours, minutes);
  return new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(date);
}




export const day_map = {
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
};


export const receptionist_action_map = {
  schedule_appointment: "Schedule an appointment",
  
}