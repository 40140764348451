
import { useEffect, useState, useRef } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../contexts/ProtectedContext'

import { CheckCircleIcon, CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'


import { Link, useLocation, useNavigate } from "react-router-dom"

import { auth_axios } from '../../../../../libs/authWeb';
import { handle_enter_keydown, show_notification, classNames, sleep } from '../../../../../libs/helpers';
import { mixpanel_client_track } from '../../../../../libs/mixpanelClient'
import { ArrowLongLeftIcon } from '@heroicons/react/24/outline'

import { ReactComponent as GuavaLogo } from "../../../../../media/icons/guava.svg"
import { validate_url } from '../../../../../libs/validate'





const website_url_scan_steps = [
  { id: "opening_url", name: "Opening URL", completed_name: "URL opened" },
  { id: "parsing_html", name: "Parsing HTML", completed_name: "HTML parsed" },
  { id: "gathering_links", name: "Gathering links", completed_name: "Links gathered" },
  { id: "parsing_links", name: "Parsing links", completed_name: "Links parsed" },
  { id: "compiling_data", name: "Compiling data", completed_name: "Data compiled" },
]


const OrganizationScanWebsiteUrlPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()
  

  // Status message
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")
  
  // Status var
  // const [status, set_status] = useState("") // one of "ongoing", "completed"
  const [step, set_step] = useState("") // one of "opening_url", "parsing_html", "gathering_links", "parsing_links", "compiling_data"
  
  // User input
  const [website_url, set_website_url] = useState("")

  const website_url_ref = useRef<any>(null);

  // Statuses
  const [is_polling, set_is_polling] = useState(false)
  const [is_awaiting, set_is_awaiting] = useState(false)

  




  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "website_url": {
        set_website_url(value)
  
        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  const get_organization_and_event = async () => {

    set_is_awaiting(true)

    // Execute get org name
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get latest scan
    const post_poll_scan_res = await auth_axios.post(`/api/organizations/poll-scan`, {
      type: "website_url_scan"
    })

    if (!post_poll_scan_res.data.success) {
      switch (post_poll_scan_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    set_website_url(get_org_res.data.organization_data.organization_metadata.website_url || "")
    // set_status(post_poll_scan_res.data.event ? (post_poll_scan_res.data.event.status === "completed" ? "" : post_poll_scan_res.data.event.status ) : "")
    set_step(post_poll_scan_res.data.event ? (post_poll_scan_res.data.event.status === "ongoing" ? post_poll_scan_res.data.event.event_metadata.step : "" ) : "")
    set_is_awaiting(false)

    // If scan event is ongoing, trigger polling
    if (post_poll_scan_res.data.event && post_poll_scan_res.data.event.status === "ongoing") {
      set_is_polling(true)
      poll_event()
    }
  }

  const poll_event = async () => {

    let status = ""
    while(status !== "completed" && window.location.href.includes("scan/website-url")) {

      // Execute get latest scan
      const post_poll_scan_res = await auth_axios.post(`/api/organizations/poll-scan`, {
        type: "website_url_scan"
      })

      if (!post_poll_scan_res.data.success) {
        switch (post_poll_scan_res.data.status) {
          case "FATAL_ERROR": {
            alert("Fatal error")
    
            // Redirect to dashboard/organization page
            navigate(`/dashboard/organization`)
            
            // Always break
            break
          }
          default: {
            // Always break
            break
          }
        }

        return
      }

      status = post_poll_scan_res.data.event ? post_poll_scan_res.data.event.status : ""
      // set_status(post_poll_scan_res.data.event ? post_poll_scan_res.data.event.status : "")
      set_step(post_poll_scan_res.data.event ? post_poll_scan_res.data.event.event_metadata.step : "")

      await sleep(1.0)
    }

    // Finish polling
    set_is_polling(false)
  }

  // Update name
  const scan_website_url = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate URL
    if (website_url.length === 0) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Please enter a valid website URL beginning with https://")

      // End of the line
      return
    }

    if (!validate_url(website_url)) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Please enter a valid website URL beginning with https://")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Execute update URL
    const put_org_url_res = await auth_axios.put(`/api/organizations/url`, {
      type: "website_url",
      url: website_url,
    })

    if (!put_org_url_res.data.success) {
      switch (put_org_url_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (put_org_url_res.data.status) {
      case "SUCCESS": {
        show_notification(protected_context, protected_update, "success", "Success", "Website URL was successfully updated. Initiating scan...")

        // Always break
        break
      }
      case "FAILURE": {
        show_notification(protected_context, protected_update, "error", "Error", "Error occurred while updating website URL. Please try again")

        navigate(`/dashboard/organization`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

    // Execute scan URL
    const post_scan_url_res = await auth_axios.post(`/api/organizations/scan/url`, {
      type: "website_url_scan",
      url: website_url,
    })

    if (!post_scan_url_res.data.success) {
      switch (post_scan_url_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_scan_url_res.data.status) {
      case "SUCCESS": {
        // set_status("ongoing")

        // Always break
        break
      }
      case "FAILURE": {
        show_notification(protected_context, protected_update, "error", "Error", "Error occurred while scanning the URL. Please try again")

        navigate(`/dashboard/organization`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

    // Set status
    set_is_awaiting(false)

    // Trigger polling
    set_is_polling(true)
    poll_event()
  }



  // Renders 
  useEffect(() => { 

    // Get data
    get_organization_and_event()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_organization_scan_website_url_visited", global_context.user_id)
    
  }, [])


  return (
    <div className="px-4 sm:px-6 lg:flex-auto lg:px-0 py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>

          {/* Back button */}
          <Link to="/dashboard/organization" className="text-gray-500 hover:text-gray-700 font-medium flex text-sm items-center space-x-2 ">
            <ArrowLongLeftIcon className="h-6 w-6" />
            <div>Back</div>
          </Link>

          {/* Title */}
          <h1 className="mt-12 text-base font-semibold leading-7 text-gray-900">Update & scan website URL</h1>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Update & scan your business website
          </p>

          
          {/* Input space */}
          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex sm:flex-col max-w-[400px] justify-center items-center">
              <div className="w-full space-y-4">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Website URL
                  </label>
                  <div className="mt-2">
                    {is_awaiting ? (
                      <ReactLoading
                        type='spokes'
                        color='#343D46'
                        height={20}
                        width={20}
                      />
                    ) : (
                      <input
                        type="text"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        ref={website_url_ref}
                        value={website_url}
                        onChange={(e) => handle_user_input("website_url", e.target.value)}
                        onKeyDown={async (e) => handle_enter_keydown(e, scan_website_url)}
                        disabled={is_awaiting || is_polling}
                      />
                    )}
                  </div>
                </div>

                {error_message
                ? <div className="mt-6 flex space-x-2 items-start">
                    <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                    <div className="text-sm font-medium text-red-400">{error_message}</div>
                  </div>
                : <></>}
                {success_message
                ? <div className="mt-6 flex space-x-2 items-start">
                    <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
                    <div className="text-sm font-medium text-green-600">{success_message}</div>
                  </div>
                : <></>}

                <button 
                  className={classNames(
                    is_awaiting || is_polling || step === "finished_scan" ? 'text-white bg-blue-500' : 'text-white bg-blue-600 hover:bg-blue-500',
                    'mt-6 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                  )}
                  onClick={scan_website_url}
                  disabled={is_awaiting || is_polling || step === "finished_scan"}
                >
                  {is_awaiting ? (
                    <div className="flex space-x-4 justify-center items-center">
                      <ReactLoading
                        type='spokes'
                        color='#ffffff'
                        height={20}
                        width={20}
                      />
                    </div>
                  ) : is_polling ? (
                    <div className="flex space-x-4 justify-center items-center">
                      <span>Scan in progress</span>
                      <ReactLoading
                        type='spokes'
                        color='#ffffff'
                        height={20}
                        width={20}
                      />
                    </div>
                  ) : step === "finished_scan" ? (
                    <div className="flex space-x-4">
                      <span>Scan completed</span>
                    </div>
                  ) : (
                    <div className="flex space-x-4">
                      <span>Update & scan</span>
                    </div>
                  )}
                </button> 
                
              </div>
            </div>
          </dl>

          {/* Progress area */}
          {is_polling || step === "finished_scan"
          ? <div className="mt-48 flex flex-col space-between justify-between w-full">

            {/* Progress bar */}
            <ol className="flex items-center">
              {website_url_scan_steps.map((website_url_scan_step, step_index) => (
                <li key={website_url_scan_step.id} className={classNames(step_index !== website_url_scan_steps.length - 1 ? 'pr-[15%]' : '', 'relative')}>
                  {website_url_scan_step.id === step ? (
                    <GuavaLogo className="absolute bottom-20 -left-5 h-28 w-auto animate-bounce-1s-infinite" />
                  ) : step === "finished_scan" && step_index === website_url_scan_steps.length - 1 ? (
                    <GuavaLogo className="absolute bottom-20 -left-5 h-28 w-auto" />
                  ) : (
                    <></>
                  )}
                  {step_index < website_url_scan_steps.findIndex(s => s.id === step) || step === "finished_scan" ? (
                    <>
                      <div aria-hidden="true" className="absolute inset-0 flex items-center">
                        <div className="h-0.5 w-full bg-blue-600" />
                      </div>
                      <div
                        className="relative flex h-8 w-8 items-center justify-center rounded-full bg-blue-600 hover:bg-blue-900"
                      >
                        <CheckIcon aria-hidden="true" className="h-5 w-5 text-white" />
                      </div>
                      <div className="absolute top-12 text-sm font-medium">{website_url_scan_step.completed_name}</div>
                    </>
                  ) : website_url_scan_step.id === step ? (
                    <>
                      <div aria-hidden="true" className="absolute inset-0 flex items-center">
                        <div className="h-0.5 w-full bg-gray-200" />
                      </div>
                      <div
                        aria-current="step"
                        className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-600 bg-white animate-pulsate-1s-infinite"
                      >
                        <span aria-hidden="true" className="h-2.5 w-2.5 rounded-full bg-blue-600" />
                      </div>
                      <div className="absolute top-12 text-sm font-medium">{website_url_scan_step.name}</div>
                    </>
                  ) : (
                    <>
                      <div aria-hidden="true" className="absolute inset-0 flex items-center">
                        <div className="h-0.5 w-full bg-gray-200" />
                      </div>
                      <div
                        className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                      >
                        <span
                          aria-hidden="true"
                          className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                        />
                      </div>
                      <div className="absolute top-12 text-sm font-medium">{website_url_scan_step.name}</div>
                    </>
                  )}
                </li>
              ))}
            </ol>
          </div>
          : <></>}
          

        </div>

      </div>
    </div>
  )
}

export default OrganizationScanWebsiteUrlPage