
import { useEffect } from 'react'

import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'




import { useGlobal, useGlobalUpdate } from '../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../contexts/ProtectedContext'


import { useLocation, useNavigate, Outlet, Link } from "react-router-dom"
import { mixpanel_client_track } from '../../../../libs/mixpanelClient'






const DocsGuidesAllowlist = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // Location
  const location = useLocation()

  // Render - handle user view banner 
  useEffect(() => {
    // Mixpanel tracking
    mixpanel_client_track("app_docs_guides_how_to_setup_scim_visited", null)
  }, [])

  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-blue-600">Guides</p>
        
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Setup allowlist for Guava</h1>

        <p className="mt-6 text-xl leading-8">
          Let's get your allowlist settings set up for a successful implementation of your phishing simulation campaigns.
        </p>

        <div className="mt-10 max-w-2xl">
          <p>
            In order for you to start phishing and training your users, you must allowlist Guava by configuring your mail server and/or spam filters in such a way that Guava emails successfully land in your users' inboxes. Your Guava customer success manager will make sure that you are set up for success by going through this configuration process with you from beginning to the end.
          </p>
          <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">

            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">Mail server, no cloud-based spam filter.</strong> The recommended setup is to allowlist our IP address / hostname in your mail server.
              </span>
            </li>

            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">Mail server & cloud-based spam filter.</strong> The recommended setup is to allowlist our IP address / hostname in your spam filter, and allowlist Guava by email header in your mail server.
              </span>
            </li>

            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">White-glove onboarding.</strong> Based on the vendors you use for mail server and/or spam filters, your customer success manager will compile a custom guide for your organization and walk through the setup process with you during the onboarding call.
              </span>
            </li>

          </ul>
        </div>

        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Guava's IP addresses, hostnames, and header</h2>

        <p className="mt-6">
          IP address: 154.38.186.39
        </p>

        <p className="mt-2">
          Hostname: mail.guavahq.net
        </p>

        <p className="mt-2">
          Phising simulation email header: X-PHISHSIM
        </p>

        {/* <div className="mt-16 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">Okta integration</h2>
          <p className="mt-6">
            For the Okta integration, the apply the tenant / base URL into the "Base URL" field, and the SCIM token into the "API Token" field. Once you "Test API Credentials", you should click on the "Save" button.
          </p>
          <p className="mt-8">
            In the "Sign On" tab, you must select "Email" for the "Application username format".
          </p>
          <p className="mt-8">
            In the "Provisioning" tab, under "To App" tab, you should enable "Create Users", "Update User Attributes", and "Deactivate Users" features. The "Sync Password" feature should be disabled.
          </p>
          <p className="mt-8">
            To assign users to Guava, go to the "Assignments" tab and assign users by finding them under "People" or "Groups". Please note that assigning users via a group will not add the group itself.
          </p>
          <p className="mt-8">
            To provision groups in Okta to Guava, go to the "Push Groups" tab, and add a group by searching for a group using the "Push Groups" feature. Please note that the "Push Groups" feature only pushes group membership onto Guava. Thus, within a group, only users who are assigned Guava will show up under the group in Guava.
          </p>
          <p className="mt-8">
            Known issues / troubleshooting. Please note that the import features of the "Import" tab should never be used. Email addresses in Guava are case insensitive (e.g. john@example.com and John@example.com are considered the same email address). Email addresses must belong to the organization domain in order to be provisioned via SCIM.
          </p>
        </div> */}


        <div className="mt-16 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">Next steps</h2>
          <p className="mt-6">
            To get set up, please reach out to <Link to="mailto:support@guavahq.com">support@guavahq.com</Link>.
          </p>
          <p className="mt-8">
            Team Guava
          </p>
        </div>
      </div>
    </div>
  )
}

export default DocsGuidesAllowlist