import { get_agent_property_value, get_user_property_value, provision_type_map } from "./formats"
import { filter_regex, timestamp_to_local_datetime } from "./helpers"
import { agent_property_options, program_property_options, user_property_options } from "./options"




export const user_search_filter_fn = (user, search_text) => {

  const search_text__trimmed_lowercase = search_text.trim().toLowerCase()

  // If search text is empty, return all
  if (search_text__trimmed_lowercase === "") {
    return true
  }

  // Search text is not empty
  else {
    // If any property matches, return true
    for (const property of user_property_options) {
      const user_value = get_user_property_value(user, property, true)
      if (user_value.toLowerCase().includes(search_text__trimmed_lowercase)) {
        return true
      }
    }

    // If nothing, return false
    return false
  }
}

export const user_filters_filter_fn = (user, filters) => {

  let is_valid = true

  for (const filter of filters) {
    const property = filter.match(filter_regex)[1]
    const filter_type = filter.match(filter_regex)[2]
    const filter_value = filter.match(filter_regex)[3]

    switch(filter_type) {
      case "includes_text": {
        const user_value = get_user_property_value(user, property, true)
        if (!user_value.toLowerCase().includes(filter_value)) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_text": {
        const user_value = get_user_property_value(user, property, true)
        if (filter_value !== user_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_option": {
        let user_value = get_user_property_value(user, property, false)

        switch(typeof user_value) {
          case "string": {
            user_value = user_value
            // Always break
            break
          }
          case "boolean": {
            user_value = user_value ? "true" : "false"
            // Always break
            break
          }
          default: {
            // Always break
            break
          }
        }

        if (filter_value !== user_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "before_date": {
        const user_value = get_user_property_value(user, property, false)
        const user_value_date = new Date(user_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() < user_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "after_date": {
        const user_value = get_user_property_value(user, property, false)
        const user_value_date = new Date(user_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() > user_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value === filter_value
        if (user_value !== filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "lte_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value <= filter_value
        if (user_value > filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "gte_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value >= filter_value
        if (user_value < filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "lt_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value < filter_value
        if (user_value >= filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "gt_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value > filter_value
        if (user_value <= filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  return is_valid
}




export const agent_search_filter_fn = (agent, search_text) => {

  const search_text__trimmed_lowercase = search_text.trim().toLowerCase()

  // If search text is empty, return all
  if (search_text__trimmed_lowercase === "") {
    return true
  }

  // Search text is not empty
  else {
    // If any property matches, return true
    for (const property of agent_property_options) {
      const agent_value = get_agent_property_value(agent, property, true)
      if (agent_value.toLowerCase().includes(search_text__trimmed_lowercase)) {
        return true
      }
    }

    // If nothing, return false
    return false
  }
}




export const agent_filters_filter_fn = (agent, filters) => {

  let is_valid = true

  for (const filter of filters) {
    const property = filter.match(filter_regex)[1]
    const filter_type = filter.match(filter_regex)[2]
    const filter_value = filter.match(filter_regex)[3]

    switch(filter_type) {
      case "includes_text": {
        const agent_value = get_agent_property_value(agent, property, true)
        if (!agent_value.toLowerCase().includes(filter_value)) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_text": {
        const agent_value = get_agent_property_value(agent, property, true)
        if (filter_value !== agent_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_option": {
        let agent_value = get_agent_property_value(agent, property, false)

        switch(typeof agent_value) {
          case "string": {
            agent_value = agent_value
            // Always break
            break
          }
          case "boolean": {
            agent_value = agent_value ? "true" : "false"
            // Always break
            break
          }
          default: {
            // Always break
            break
          }
        }

        if (filter_value !== agent_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "before_date": {
        const agent_value = get_agent_property_value(agent, property, false)
        const agent_value_date = new Date(agent_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() < agent_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "after_date": {
        const agent_value = get_agent_property_value(agent, property, false)
        const agent_value_date = new Date(agent_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() > agent_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_number": {
        const agent_value = get_agent_property_value(agent, property, false)
        const filter_value_number = Number(filter_value)
        
        // agent_value === filter_value
        if (agent_value !== filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "lte_number": {
        const agent_value = get_agent_property_value(agent, property, false)
        const filter_value_number = Number(filter_value)
        
        // agent_value <= filter_value
        if (agent_value > filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "gte_number": {
        const agent_value = get_agent_property_value(agent, property, false)
        const filter_value_number = Number(filter_value)
        
        // agent_value >= filter_value
        if (agent_value < filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "lt_number": {
        const agent_value = get_agent_property_value(agent, property, false)
        const filter_value_number = Number(filter_value)
        
        // agent_value < filter_value
        if (agent_value >= filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "gt_number": {
        const agent_value = get_agent_property_value(agent, property, false)
        const filter_value_number = Number(filter_value)
        
        // agent_value > filter_value
        if (agent_value <= filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  return is_valid
}

