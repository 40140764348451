import { useEffect, useState, useRef, Fragment } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../../contexts/ProtectedContext'

import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { RadioGroup, Transition, Listbox } from '@headlessui/react'

import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'


import { Link, useLocation, useNavigate } from "react-router-dom"

import { auth_axios } from '../../../../../../libs/authWeb';
import { handle_enter_keydown, show_notification, classNames, sleep } from '../../../../../../libs/helpers';
import { mixpanel_client_track } from '../../../../../../libs/mixpanelClient'
import { ArrowLongLeftIcon } from '@heroicons/react/24/outline'
import { receptionist_mode_options } from '../../../../../../libs/options'
import { format_phone_number, receptionist_mode_map } from '../../../../../../libs/formats'



const add_options = [
  { type: "upload_csv", title: 'Upload CSV', description: 'Upload users in bulk by preparing and uploading a CSV file', users: 'Bulk upload', to: "upload/csv", active: false },
  { type: "input_profile", title: 'Add a single profile', description: 'Add a single profile by entering data manually', users: 'Manually input single profile', to: "input/profile", active: false },
  { type: "invite_emails", title: 'Invite users', description: 'Invite users in your organization to create account and self-report data during signup', users: 'Invitations sent via email', to: "invite/emails", active: true },
  { type: "configure_scim", title: 'Integrate SCIM', description: 'Automatically provision and deprovision users via SCIM', users: 'Automatic provisioning / deprovisioning', to: "/dashboard/organization", active: false },
]





const OrganizationUpdateReceptionistConfigurationPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()
  

  // Status message
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")

  // Options
  const [agents, set_agents] = useState([])

  // Data
  const [organization_data, set_organization_data] = useState({})
  
  // User input
  const [receptionist_agent_id, set_receptionist_agent_id] = useState("")
  const [receptionist_mode, set_receptionist_mode] = useState("")
  const [confirm_text, set_confirm_text] = useState("")

  const organization_mode_ref = useRef<any>(null);

  // Statuses
  const [organization_data_is_fetched, set_organization_data_is_fetched] = useState(false)
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [is_updated, set_is_updated] = useState(false)




  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "receptionist_mode": {
        set_receptionist_mode(value)
  
        // Always break
        break
      }
      case "receptionist_agent_id": {
        set_receptionist_agent_id(value)
  
        // Always break
        break
      }
      case "confirm_text": {
        set_confirm_text(value)
  
        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }


  const get_organization = async () => {

    set_organization_data_is_fetched(false)

    // Execute get org
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get agents
    const get_agents_res = await auth_axios.get(`/api/agents`)

    if (!get_agents_res.data.success) {
      switch (get_agents_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    const current_receptionist_mode = get_org_res.data.organization_data.organization_metadata.receptionist_mode
    const current_receptionist_agent_id = get_org_res.data.organization_data.organization_metadata.receptionist_agent_id
    const agents = get_agents_res.data.agents
    
    if (agents.length < 1) {
      show_notification(protected_context, protected_update, "warning", "Warning", "Please add an agent first")

      // Redirect to dashboard/agents page
      navigate(`/dashboard/agents/add`)

      // Safety return
      return
    }
    
    set_organization_data(get_org_res.data.organization_data)
    set_receptionist_agent_id((!current_receptionist_agent_id || current_receptionist_agent_id === "UNSET") ? agents[0].agent_id : current_receptionist_agent_id)
    set_receptionist_mode((!current_receptionist_mode || current_receptionist_mode === "UNSET") ? "front_desk" : current_receptionist_mode)
    set_agents(agents)
    
    set_organization_data_is_fetched(true)
  }



  // Update mode
  const update_mode = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Receptionist is selected 
    if (receptionist_agent_id.length === 0) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Please select the receptionist agent")

      // End of the line
      return
    }

    // Validate receptionst mode 
    if (!["front_desk", "after_hours"].includes(receptionist_mode)) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Please select the receptionist mode")

      // End of the line
      return
    }

    // Validate confirm text 
    if (confirm_text !== `I completed ${receptionist_mode_map[receptionist_mode].toLowerCase()} instructions`) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Confirm text error")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Execute update mode
    const put_org_mode_res = await auth_axios.put(`/api/organizations/receptionist/configuration`, {
      receptionist_agent_id: receptionist_agent_id,
      receptionist_mode: receptionist_mode,
    })

    if (!put_org_mode_res.data.success) {
      switch (put_org_mode_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (put_org_mode_res.data.status) {
      case "SUCCESS": {
        set_is_updated(true)
        set_is_awaiting(false)
        set_success_message("Receptionist configuration has been updated")

        // Always break
        break
      }
      case "FAILURE": {
        // alert("Error occurred while updating organization mode. Please try again")
        show_notification(protected_context, protected_update, "error", "Error", "Error occurred while updating receptionist configuration. Please try again")

        navigate(`/dashboard/account`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

  }

  // Renders 
  useEffect(() => {
    
    // Get organization data
    get_organization()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_organization_update_mode_visited", global_context.user_id)
    
  }, [])


  return (
    <div className="px-4 sm:px-6 lg:flex-auto lg:px-0 py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>

          <Link to="/dashboard/organization" className="text-gray-500 hover:text-gray-700 font-medium flex text-sm items-center space-x-2 ">
            <ArrowLongLeftIcon className="h-6 w-6" />
            <div>Back</div>
          </Link>

          <h1 className="mt-12 text-base font-semibold leading-7 text-gray-900">Update receptionist configuration</h1>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Update the configuration of your AI receptionist
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex sm:flex-col justify-center items-center">
              <div className="w-full space-y-4">



                {/* Mode */}
                <div>
                  <label className="block text-sm leading-6 text-gray-900 flex">
                    <span className="font-semibold">Select the mode for your AI receptionist</span>
                    <span className="flex relative items-center">
                      <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                      <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                        The type of simulation to run. Multi-message simulation is limited to up to 20 users. Please contact support@guavahq.com to increase this limit.
                      </span>
                    </span>
                  </label>
                  <div className="mt-2">
                    <RadioGroup value={receptionist_mode_options.find(mode_option => mode_option === receptionist_mode)} onChange={(e) => handle_user_input("receptionist_mode", e)} disabled={is_awaiting || is_updated} >
                      <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                        {receptionist_mode_options.map((receptionist_mode_option) => (
                          <RadioGroup.Option
                            key={receptionist_mode_option}
                            value={receptionist_mode_option}
                            className={({ active }) =>
                              classNames(
                                active ? 'border-blue-600 ring-2 ring-blue-600' : 'border-gray-300',
                                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                              )
                            }
                          >
                            {({ checked, active }) => (
                              <>
                                <span className="flex flex-1">
                                  <span className="flex flex-col">
                                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                      {receptionist_mode_map[receptionist_mode_option]}
                                    </RadioGroup.Label>
                                    <RadioGroup.Description as="span" className="mt-3 text-sm font-normal text-gray-500">
                                      {(() => {
                                        switch (receptionist_mode_option) {
                                          case "front_desk": {
                                            return "Basic single-send phishing simulation with templated phishing emails. Basic customization such as modes, department, and position can be applied."
                                          }
                                          case "after_hours": {
                                            return "Advanced multi-message phishing simulation with sophisticated personalization. The Simulation AI generates phishing emails based on the scenario that you specify, and engages in a spear-phishing email conversation customized for each user. The Simulation AI can receive and parse email replies from users as well as sending its own."
                                          }
                                          default: {
                                            return ""
                                          }
                                        }
                                      })()}
                                    </RadioGroup.Description>
                                  </span>
                                </span>
                                <CheckCircleIcon
                                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-blue-600')}
                                  aria-hidden="true"
                                />
                                <span
                                  className={classNames(
                                    active ? 'border' : 'border-2',
                                    checked ? 'border-blue-600' : 'border-transparent',
                                    'pointer-events-none absolute -inset-px rounded-lg'
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                </div>

                {/* Select agent */}
                <div>
                  <Listbox value={receptionist_agent_id} onChange={(e) => handle_user_input("type", e)} disabled={is_awaiting || is_updated} >
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm leading-6 text-gray-900 flex">
                          <span className="font-semibold">Select agent</span>
                          <span className="flex relative items-center">
                            <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                            <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                              For simulation + training, the training module sent to users will be individually customized to explain the scenario they received, as well as going over what happened for the user during the simulation. For training only, you must choose the training module / topic that selected users will receive.
                            </span>
                          </span>
                        </Listbox.Label>
                        <div className="relative mt-2 w-64">
                          <Listbox.Button 
                            className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                          >
                            {organization_data_is_fetched ? (
                              <span className="block truncate">
                                {`${agents.find((agent) => agent.agent_id === receptionist_agent_id).agent_metadata.name} | ${format_phone_number(agents.find((agent) => agent.agent_id === receptionist_agent_id).phone_number)}`}
                              </span>
                            ) : (
                              <ReactLoading
                                type='spokes'
                                color='#343D46'
                                height={20}
                                width={20}
                              />
                            )}
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {agents.map((agent) => (
                                <Listbox.Option
                                  key={agent.agent_id}
                                  className={({ active }) =>
                                    classNames(
                                      active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                      'relative cursor-default select-none py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={agent.agent_id}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                        {`${agent.agent_metadata.name} | ${format_phone_number(agent.phone_number)}`}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active ? 'text-white' : 'text-blue-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>

                {/* Confirm text */}
                <div>
                  <label className="block text-sm leading-6 text-gray-900 flex">
                    <span className="font-semibold">Instructions for {receptionist_mode_map[receptionist_mode] && receptionist_mode_map[receptionist_mode].toLowerCase()}</span>
                    <span className="flex relative items-center">
                      <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                      <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                        The type of simulation to run. Multi-message simulation is limited to up to 20 users. Please contact support@guavahq.com to increase this limit.
                      </span>
                    </span>
                  </label>
                  <div className="mt-2 space-y-2">
                    {/* Instructions */}
                    <div>
                      {receptionist_mode === "front_desk" ? (
                        `For front desk mode, ${agents.find((agent) => agent.agent_id === receptionist_agent_id).agent_metadata.name} will be the main receptionist for ${organization_data["organization_metadata"]["name"]}. Make sure to update ${organization_data["organization_metadata"]["name"]}'s online presence (website, Google Maps, Instagram, etc.) to point prospective callers towards ${format_phone_number(agents.find((agent) => agent.agent_id === receptionist_agent_id).phone_number)} (tip: don't forget to mention that the phone line will be available 24/7). For this mode, callers who ask to be transferred to a human will be transferred to ${organization_data["organization_metadata"]["name"]}'s business number if the transfer request is made during human reachable hours. Also make sure to review your ${organization_data["organization_metadata"]["name"]}'s business hours & human reachable hours. You do not need to set up separate voicemail forwarding for this mode. After completion, please type "I completed ${receptionist_mode_map[receptionist_mode] && receptionist_mode_map[receptionist_mode].toLowerCase()} instructions" below and update.`
                      ) : receptionist_mode === "after_hours" ? (
                        `For after hours mode, callers will be re-routed to ${agents.find((agent) => agent.agent_id === receptionist_agent_id).agent_metadata.name} whenever they reach the voicemail of ${organization_data["organization_metadata"]["name"]}'s business phone number. For this mode, you must set up forwarding from ${organization_data["organization_metadata"]["name"]}'s voicemail to ${format_phone_number(agents.find((agent) => agent.agent_id === receptionist_agent_id).phone_number)}. After completion, please type "I completed ${receptionist_mode_map[receptionist_mode] && receptionist_mode_map[receptionist_mode].toLowerCase()} instructions" below and update.`
                      ) : (
                        ""
                      )}
                    </div>

                    {/* Confirm text */}
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={confirm_text}
                      placeholder={`I completed ${receptionist_mode_map[receptionist_mode] && receptionist_mode_map[receptionist_mode].toLowerCase()} instructions`}
                      onChange={(e) => handle_user_input("confirm_text", e.target.value)}
                      disabled={is_awaiting || is_updated}
                    />
                  </div>
                </div>

                {error_message
                ? <div className="mt-6 flex space-x-2 items-start">
                    <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                    <div className="text-sm font-medium text-red-400">{error_message}</div>
                  </div>
                : <></>}
                {is_updated && success_message
                ? <div className="mt-6 flex space-x-2 items-start">
                    <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
                    <div className="text-sm font-medium text-green-600">{success_message}</div>
                  </div>
                : <></>}

                <div className="w-full flex justify-end">
                  <button 
                    className={classNames(
                      is_awaiting || is_updated || (confirm_text !== `I completed ${receptionist_mode_map[receptionist_mode] && receptionist_mode_map[receptionist_mode].toLowerCase()} instructions`) ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500', 'flex w-[100px] justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                    )}
                    onClick={update_mode}
                    disabled={is_awaiting || is_updated || (confirm_text !== `I completed ${receptionist_mode_map[receptionist_mode] && receptionist_mode_map[receptionist_mode].toLowerCase()} instructions`)}
                  >
                    {!is_awaiting
                    ? <span>Update</span>
                    : <ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />}
                  </button> 
                </div>
                
                
              </div>
            </div>
          </dl>
        </div>

      </div>
    </div>
  )
}

export default OrganizationUpdateReceptionistConfigurationPage