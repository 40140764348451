
import { useEffect } from 'react'

import { Link } from "react-router-dom"
import { useGlobal, useGlobalUpdate } from '../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../contexts/ProtectedContext'

import { useLocation, useNavigate } from "react-router-dom"
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { ArrowLongLeftIcon } from '@heroicons/react/24/outline'


import { mixpanel_client_track } from '../../../../libs/mixpanelClient';
import { classNames, show_notification } from '../../../../libs/helpers'


const add_options = [
  { type: "upload_csv", title: 'Upload CSV', description: 'Upload users in bulk by preparing and uploading a CSV file', users: 'Bulk upload', to: "upload/csv", active: false },
  { type: "input_profile", title: 'Add a single profile', description: 'Add a single profile by entering data manually', users: 'Manually input single profile', to: "input/profile", active: false },
  { type: "invite_emails", title: 'Invite users', description: 'Invite users in your organization to create account and self-report data during signup', users: 'Invitations sent via email', to: "invite/emails", active: true },
  { type: "configure_scim", title: 'Integrate SCIM', description: 'Automatically provision and deprovision users via SCIM', users: 'Automatic provisioning / deprovisioning', to: "/dashboard/organization", active: false },
]





const UsersAddPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  const handle_click = (type) => {
    switch(type) {
      case "upload_csv": {
        // Always break
        break
      }
      case "input_profile": {
        // Always break
        break
      }
      case "invite_emails": {
        // Always break
        break
      }
      case "configure_scim": {
        show_notification(protected_context, protected_update, "warning", "Configure SCIM settings", "Configure organization settings to provision / deprovision users via SCIM.")

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  // Renders  
  useEffect(() => {

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_users_add_visited", global_context.user_id)

  }, [])

  return (
    <div className="px-4 sm:px-6 lg:flex-auto lg:px-0 py-8">
      <div className="sm:flex sm:items-center">

        <div className="sm:flex-auto">
          <Link to={`/dashboard/users`} className="text-gray-500 hover:text-gray-700 font-medium flex text-sm items-center space-x-2 ">
            <ArrowLongLeftIcon className="h-6 w-6" />
            <div>Back</div>
          </Link>
          <h1 className="mt-12 text-base font-semibold leading-7 text-gray-900">Add users</h1>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Please choose the method for adding users
          </p>
        </div>

      </div>
      <div className="mt-8">

        <div>
          <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
            {add_options.map((add_option) => (
              <Link
                key={add_option.type}
                className={classNames(add_option.active ? "bg-white hover:border-blue-600 hover:ring-1 hover:ring-blue-600 cursor-pointer" : "bg-gray-50 cursor-default", "border-gray-300 relative flex rounded-lg border p-4 shadow-sm focus:outline-none")}
                to={add_option.active ? add_option.to : ""}
                onClick={add_option.active ? () => handle_click(add_option.type) : null}

              >
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <div className="block text-sm font-medium text-gray-900">
                      {add_option.title}
                    </div>
                    <div className="mt-1 flex items-center text-sm text-gray-500">
                      {add_option.description}
                    </div>
                    <div className="mt-6 text-sm font-medium text-gray-900">
                      {add_option.users}
                    </div>
                  </span>
                </span>
                <CheckCircleIcon
                  className={'invisible h-5 w-5 text-indigo-600'}
                  aria-hidden="true"
                />
                <span
                  className='pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent'
                  aria-hidden="true"
                />
              </Link>
            ))}
          </div>
        </div>

      </div>
    </div>
  )
}

export default UsersAddPage