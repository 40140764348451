
import { useEffect, useState } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'

import { useLocation, Link } from "react-router-dom"

import { auth_axios } from '../../../libs/authWeb';
import { get_date_string_short } from '../../../libs/formats';
import { mixpanel_client_track } from '../../../libs/mixpanelClient';
import { beautify_usd_amount, get_organization_status_pretty, get_invoice_status } from '../../../libs/formats';
import { get_payment_method_pretty } from '../../../libs/jsxFormats';




const StripeInvoice = ({
  invoice
} : {
  invoice : any
}) => {
  return (
    <div className="px-4 flex justify-between">
      {invoice.status !== "draft"
      ? <Link to={invoice.hosted_invoice_url} target="_blank" className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-blue-600 hover:text-blue-900 sm:pl-0">
          {get_date_string_short(invoice.period_end * 1000)}
        </Link>
      : <div className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-800 hover:text-gray-900 sm:pl-0">{get_date_string_short(invoice.period_end * 1000)}</div>}
      <div className={invoice.status === "void" ? "whitespace-nowrap px-3 py-4 text-sm text-gray-500 line-through" : "whitespace-nowrap px-3 py-4 text-sm text-gray-500"}>{beautify_usd_amount(invoice.total)}</div>
      <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{get_invoice_status(invoice.total, invoice.status)}</div>
      <div className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
        {invoice.status !== "draft"
        ? invoice.status === "open"
          ? <Link to={invoice.hosted_invoice_url} target="_blank" className="text-red-400 hover:text-red-500">
              Settle invoice
            </Link>
          : <Link to={invoice.invoice_pdf} target="_blank" className="text-blue-600 hover:text-blue-900">
              Download invoice
            </Link>
        : <div>Not available</div>}
      </div>
    </div>
  )
}


const PlanPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // User data
  const [organization_data, set_organization_data] = useState({})
  const [organization_data_is_fetched, set_organization_data_is_fetched] = useState(false)


  const get_organization_data = async () => {
    // Set is_fetched to false
    set_organization_data_is_fetched(false)

    // Execute get user data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_organization_data(get_org_res.data.organization_data)
    set_organization_data_is_fetched(true)
  }



  // Renders 
  useEffect(() => {

    get_organization_data()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_plan_visited", global_context.user_id)

  }, [])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Plan & Billing</h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Plan and billing information for your organization
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">

            {/* Status */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Plan status</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    <span>
                      {get_organization_status_pretty(organization_data["status"])}
                      {organization_data["status"] === "inactive" ? <><br/><div className="mt-4 text-gray-500">To activate your plan,{organization_data["payment_method"] === "UNSET" ? " add payment method and " : " add"} seats to your plan below</div></> : ""}
                    </span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                {/* <Link to="update/status" className="font-semibold text-blue-600 hover:text-blue-500">
                  Update
                </Link> */}
              </dd>
            </div>

            {/* Account credit / balance */}
            {organization_data["balance"]
              // Account credit
            ? organization_data["balance"] < 0
              ? <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Account Credit</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">
                      {beautify_usd_amount(organization_data["balance"]).slice(1)}
                      <div className="mt-4 text-gray-500">This credit will be applied to be deducted from your next invoice</div>
                    </div>
                  </dd>  
                </div>
              // Account balance
              : organization_data["balance"] > 0 
                ? <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Account Balance</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {beautify_usd_amount(organization_data["balance"])}
                        <div className="mt-4 text-gray-500">This balance will be added as a surcharge in your next invoice</div>
                      </div>
                    </dd>  
                  </div>
                  // Don't show anything if account balance is 0
                : <></>
              // Don't show anything if account balance is null 
            : <></> }

            {/* Payment method */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Payment method</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    <span>
                      {get_payment_method_pretty(organization_data)}
                      {organization_data["payment_method"] === "UNSET" ? <><br/><div className="mt-4 text-gray-500">Please contact support@guavahq.com for custom contract and payment method</div></> : ""}
                    </span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>

                {/* Show update button only if current payment method is not set or is Stripe */}
                {["UNSET", "stripe"].includes(organization_data["payment_method"])
                ? <Link to="update/payment" className="font-semibold text-blue-600 hover:text-blue-500">
                  Update
                </Link>
                : <></>}
              </dd>  
            </div>

            {/* Number of seats & billing cycle */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Number of seats & billing cycle</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    <span>{organization_data["num_seats"]}{organization_data["billing_cycle"] === "UNSET" ? " seat(s)" : ` seat(s), billed ${organization_data["billing_cycle"]}`}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>

                {/* Show update button only if current payment method is not set or is Stripe */}
                {["UNSET", "stripe"].includes(organization_data["payment_method"])
                ? <Link to="update/plan" className="font-semibold text-blue-600 hover:text-blue-500">
                  Update
                </Link>
                : <></>}
              </dd>
            </div>

            {/* Billing history */}
            {organization_data["status"] === "active" && organization_data["payment_method"] === "stripe"
            ? <div className="pt-6 sm:flex sm:flex-col">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Billing history</dt>
              <dd className="mt-2 divide-y divide-gray-300 bg-gray-50 rounded-lg">
    
                <div className="divide-y divide-gray-200 max-h-[500px] overflow-y-auto">
                  {organization_data_is_fetched
                  ? !organization_data["upcoming_invoice"] || organization_data["past_invoices"].length === 0
                    ? <div className="w-full px-4 py-2">No invoice to show</div>
                    : <>
                        {/* Upcoming invoice */}
                        <StripeInvoice key={organization_data["upcoming_invoice"]["id"]} invoice={organization_data["upcoming_invoice"]}/>
                        {/* Past invoices */}
                        {organization_data["past_invoices"].map((invoice) => (
                          <StripeInvoice key={invoice.id} invoice={invoice}/>
                        ))}
                      </>
                  : <div className="w-full py-4 flex justify-center"> 
                      <ReactLoading
                        type='spokes'
                        color='#343D46'
                        height={20}
                        width={20}
                      />
                    </div>
                  }
                </div>
              </dd>
            </div>
            : <></>}

          </dl>
        </div>

      </div>
    </div>
  )
}

export default PlanPage