import { useEffect, useState, useRef } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../../contexts/ProtectedContext'

import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'


import { Link, useLocation, useNavigate } from "react-router-dom"

import { auth_axios } from '../../../../../../libs/authWeb';
import { handle_enter_keydown, show_notification, classNames, sleep } from '../../../../../../libs/helpers';
import { mixpanel_client_track } from '../../../../../../libs/mixpanelClient'
import { ArrowLongLeftIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Disclosure, Tab } from '@headlessui/react'
import { receptionist_action_options } from '../../../../../../libs/options'
import { receptionist_action_map } from '../../../../../../libs/formats'






const OrganizationUpdateReceptionistActionsPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()
  

  // Status message
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")
  
  // User input
  const [receptionist_actions, set_receptionist_actions] = useState([])
  const [receptionist_instructions, set_receptionist_instructions] = useState({})

  // Statuses
  const [organization_data_is_fetched, set_organization_data_is_fetched] = useState(false)
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [is_updated, set_is_updated] = useState(false)




  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "receptionist_actions": {

        // Action is enabled
        if (value[1]) {

          // Update receptionist_actions
          set_receptionist_actions([ ...receptionist_actions, value[0] ])

          // Update receptionist_instructions
          const new_receptionist_instructions = { ...receptionist_instructions }
          if (!(value[0] in new_receptionist_instructions)) {
            switch (value[0]) {
              case "schedule_appointment": {
                new_receptionist_instructions[value[0]] = {
                  intake_instructions: "",
                  notification_instructions: "",
                  notification_email_address: ""
                }
                // Always break
                break
              }
              default: {
                // Always break
                break
              }
            }
          }
          set_receptionist_instructions(new_receptionist_instructions)
        }
        // Action is disabled
        else {

          // Update receptionist_actions
          set_receptionist_actions(receptionist_actions.filter(action => action !== value[0]))

          // // Update receptionist_instructions
          // const new_receptionist_instructions = { ...receptionist_instructions }
          // delete new_receptionist_instructions[value[0]]
          // set_receptionist_instructions(new_receptionist_instructions)
        }
  
        // Always break
        break
      }
      case "receptionist_instructions": {
        const new_receptionist_instructions = { ...receptionist_instructions }
        new_receptionist_instructions[value[0]] = {
          ...new_receptionist_instructions[value[0]],
          [value[1]]: value[2]
        }
        set_receptionist_instructions(new_receptionist_instructions)
  
        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }


  const get_organization = async () => {

    set_organization_data_is_fetched(false)

    // Execute get org
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    set_receptionist_actions(get_org_res.data.organization_data.organization_metadata.receptionist_actions || [])
    set_receptionist_instructions(get_org_res.data.organization_data.organization_metadata.receptionist_instructions || {})
    set_organization_data_is_fetched(true)
  }



  // Update name
  const update_receptionist_actions = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate first name
    if (false) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Please enter the ____")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Execute update name
    const put_receptionist_actions_res = await auth_axios.put(`/api/organizations/receptionist/actions`, {
      receptionist_actions: receptionist_actions,
      receptionist_instructions: receptionist_instructions,
    })

    if (!put_receptionist_actions_res.data.success) {
      switch (put_receptionist_actions_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/organization page
          navigate(`/dashboard/organization`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (put_receptionist_actions_res.data.status) {
      case "SUCCESS": {
        set_is_updated(true)
        set_is_awaiting(false)
        set_success_message("Receptionist instructions has been updated")

        // Always break
        break
      }
      case "FAILURE": {
        // alert("Error occurred while updating organization name. Please try again")
        show_notification(protected_context, protected_update, "error", "Error", "Error occurred while updating receptionist actions. Please try again")

        navigate(`/dashboard/organization`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

  }

  // Renders 
  useEffect(() => {
    
    // Get organization data
    get_organization()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_organization_update_receptionist_actions_visited", global_context.user_id)
    
  }, [])





  // // TEMP
  // useEffect(() => {
    
  //   console.log("receptionist_actions", receptionist_actions)
  //   console.log("receptionist_instructions", receptionist_instructions)
    
  // }, [receptionist_actions, receptionist_instructions])

  


  return (
    <div className="px-4 sm:px-6 lg:flex-auto lg:px-0 py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>

          <Link to="/dashboard/organization" className="text-gray-500 hover:text-gray-700 font-medium flex text-sm items-center space-x-2 ">
            <ArrowLongLeftIcon className="h-6 w-6" />
            <div>Back</div>
          </Link>

          <h1 className="mt-12 text-base font-semibold leading-7 text-gray-900">Update receptionist actions & instructions</h1>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Update actions & instructions for your AI receptionist
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex sm:flex-col max-w-3xl justify-center items-center">
              <div className="w-full space-y-4">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Receptionist actions
                  </label>
                  <div className="mt-6 space-y-4">
                    {organization_data_is_fetched ? (
                      receptionist_action_options.map((option) => (
                        <Disclosure key={option} as="div" className="flex flex-col space-y-2">
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex items-center space-x-4">

                                {/* Checkbox */}
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                  checked={receptionist_actions.includes(option)}
                                  onChange={(e) => handle_user_input("receptionist_actions", [option, e.target.checked])}
                                  onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
                                />
                                
                                {/* Text */}
                                <div className="">
                                  {receptionist_action_map[option]}
                                </div>
                                
                                {/* Chevron */}
                                <span className="ml-6 flex h-7 items-center">
                                  {open ? (
                                    <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                                  ) : (
                                    <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                                  )}
                                </span>
                              </Disclosure.Button>

                              <Disclosure.Panel as="div" className="">
                                <div className="flex flex-col space-y-4">
                                  {(() => {
                                    switch (option) {
                                      case "schedule_appointment": {
                                        return (
                                          <>
                                            {/* Intake instructions */}
                                            <div className="">
                                              <div className="">
                                                Intake instructions
                                              </div>
                                              <textarea
                                                className={classNames(!receptionist_actions.includes(option) || is_awaiting || is_updated ? "bg-gray-50" : "", "block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6")}
                                                rows={6}
                                                value={receptionist_instructions[option] && receptionist_instructions[option].intake_instructions || ""}
                                                onChange={(e) => handle_user_input("receptionist_instructions", [option, "intake_instructions", e.target.value])}
                                                disabled={!receptionist_actions.includes(option) || is_awaiting || is_updated}
                                              />
                                            </div>
                                            

                                            {/* Notification instructions */}
                                            <div className="">
                                              <div className="">
                                                Notification instructions
                                              </div>
                                              <textarea
                                                className={classNames(!receptionist_actions.includes(option) || is_awaiting || is_updated ? "bg-gray-50" : "", "block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6")}
                                                rows={6}
                                                value={receptionist_instructions[option] && receptionist_instructions[option].notification_instructions || ""}
                                                onChange={(e) => handle_user_input("receptionist_instructions", [option, "notification_instructions", e.target.value])}
                                                disabled={!receptionist_actions.includes(option) || is_awaiting || is_updated}
                                              />
                                            </div>
                                            
                  
                                            {/* Notification email address */}
                                            <div className="">
                                              <div className="">
                                                Notification email address
                                              </div>
                                              <input
                                                type="text"
                                                required
                                                className={classNames(!receptionist_actions.includes(option) || is_awaiting || is_updated ? "bg-gray-50" : "", "block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6")}
                                                value={receptionist_instructions[option] && receptionist_instructions[option].notification_email_address || ""}
                                                onChange={(e) => handle_user_input("receptionist_instructions", [option, "notification_email_address", e.target.value])}
                                                disabled={!receptionist_actions.includes(option) || is_awaiting || is_updated}
                                              />
                                            </div>
                                          </>
                                        )
                                      }
                                      default: {
                                        return (
                                          <></>
                                        )
                                      }
                                    }
                                  })()}
                                </div>

                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure> 
                      ))
                    ) : (
                      <ReactLoading
                        type='spokes'
                        color='#343D46'
                        height={20}
                        width={20}
                      />
                    )}
                  </div>
                </div>

                {error_message
                ? <div className="mt-6 flex space-x-2 items-start">
                    <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                    <div className="text-sm font-medium text-red-400">{error_message}</div>
                  </div>
                : <></>}
                {is_updated && success_message
                ? <div className="mt-6 flex space-x-2 items-start">
                    <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
                    <div className="text-sm font-medium text-green-600">{success_message}</div>
                  </div>
                : <></>}

                <div className="w-full flex justify-end">
                  <button 
                    className={classNames(
                      is_awaiting || is_updated ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                      'flex w-[100px] justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                    )}
                    onClick={update_receptionist_actions}
                    disabled={is_awaiting || is_updated}
                  >
                    {!is_awaiting
                    ? <span>Update</span>
                    :<ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />}
                  </button> 
                </div>
                
                
              </div>
            </div>
          </dl>
        </div>

      </div>
    </div>
  )
}

export default OrganizationUpdateReceptionistActionsPage